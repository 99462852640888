import Http from './axiosInstance';
import { EXCLUDE_URL } from '../data/constant';
import Alert from '../feature/Alert';

let headers = {};

let loadingFunctions = null;

export const setLoadingFunctions = (functions) => {
  loadingFunctions = functions;
};

const startLoading = () => {
  if (loadingFunctions && loadingFunctions.startLoading) {
    loadingFunctions.startLoading();
  }
};

const completeLoading = () => {
  if (loadingFunctions && loadingFunctions.completeLoading) {
    loadingFunctions.completeLoading();
  }
};

const httpClient = {

  get: async (endpoint) => {
    try {
      const response = await Http.get(endpoint);
      return response.data;
    } catch (error) {
      Alert.failure(error.message);
      throw new Error(error.message);
    }
  },

  post: async (endpoint, payLoad) => {
    startLoading();
    if (!EXCLUDE_URL.includes(endpoint)) {
      headers = { 'token': localStorage.getItem('token') };
    }
    try {
      const response = await Http.post(endpoint, payLoad, { headers: headers });
      completeLoading();
      return { "requestStatus": 'OK', "result": response.data };
    } catch (error) {
      completeLoading();
      Alert.failure(error.message);
      return { "requestStatus": 'ERROR', "message": error.message };
    }
  },

  sendRequest: async (endpoint, payLoad) => {
    if (!EXCLUDE_URL.includes(endpoint)) {
      headers = { 'token': localStorage.getItem('token') };
    }
    try {
      const response = await Http.post(endpoint, payLoad, { headers: headers });
      return { "requestStatus": 'OK', "result": response.data };
    } catch (error) {
      Alert.failure(error.message);
      return { "requestStatus": 'ERROR', "message": error.message };
    }
  },

  upload: async (endpoint, formData) => {
    startLoading();
    if (!EXCLUDE_URL.includes(endpoint)) {
      headers = { 'token': localStorage.getItem('token') };
    }
    try {
      const response = await Http.post(endpoint, formData, { headers: { ...headers, 'Content-Type': 'multipart/form-data' } });
      completeLoading();
      return { "requestStatus": 'OK', "result": response.data };
    } catch (error) {
      completeLoading();
      Alert.failure(error.message);
      return { "requestStatus": 'ERROR', "message": error.message };
    }
  },
};

export default httpClient;  
