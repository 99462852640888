import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import TableLayout from '../layout/TableLayout'
import { ADMISSION_LIST } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import FormLayout from '../layout/FormLayout';
import { ADMIT_STATUS} from '../../../data/constant';

export default function AdmissionList() {

     const [admissionList, setAdmissionList] = useState([]);

     const tableHeaders = ['#','', 'IPD No', 'Name', 'AD Date', 'AD Time', 'Dis Date', 'Dis Time', 'Status'];

     const keys = ["btnEdit","ipd_no", "patient_name", 'admission_date', "admission_time", "discharge_date", "discharge_time", "status",];

     const btnData = {
          btnEdit: { name: "Edit", link: "/main/admission/update" },
          btn3: { name: "View", link: "/main/admission/view" },
     }

     useEffect(() => {
          getAdmissionList();
     }, []);

     const getAdmissionList = async (payload = {}) => {
          const res = await httpClient.post(ADMISSION_LIST, payload);
          if (res.requestStatus == "OK") {
               const result = res.result;
               if (result.status == 1) {
                    setAdmissionList(result.data);
               }
          }
     }

     const [formData, setFormData] = useState({
          formTitle: 'Search Customer',
          formFields: [
               { label: 'From Date', id: 'from_date', type: 'date', name: 'from_date', value: '', className: "col-md-2" },
               { label: 'To Date', id: 'to_date', type: 'date', name: 'to_date', value: '', className: "col-md-2" },
               { label: 'Mobile', id: 'mobile', type: 'number', name: 'mobile', value: '', className: "col-md-3", placeholder: 'Enter mobile' },
          ],
          formErrors: { from_date: '', to_date: '', mobile: '', number: '' },
     });

     const handleFieldChange = (fieldName, value) => {
          setFormData((prevFormData) => {
               const updatedFormFields = prevFormData.formFields.map((field) =>
                    field.name === fieldName ? { ...field, value: value } : field
               );
               return {
                    ...prevFormData,
                    formFields: updatedFormFields,
               };
          });
     }

     const handleFieldBlur = () => { }

     const handleSubmit = (e) => {
          e.preventDefault();
          let post = {};
          const updatedFormFields = formData.formFields.map((field) => {
               post[field.name] = field.value;
               return field;
          });
          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
          }));
          getAdmissionList(post);
     }

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Admissions" newButton="New" newButtonLink="main/admission/new" />
                    <div className="col-lg-12 grid-margin stretch-card">
                         <div className="card">
                              <div className="card-body">
                                   <div className='mb-2'>
                                        <FormLayout
                                             formTitle={formData.formTitle}
                                             formFields={formData.formFields}
                                             formErrors={formData.formErrors}
                                             onFieldChange={handleFieldChange}
                                             onSubmit={handleSubmit}
                                             onFieldBlur={handleFieldBlur}
                                             search
                                             isModal
                                        />
                                   </div>
                                   <TableLayout
                                        headers={tableHeaders}
                                        keys={keys}
                                        tableData={admissionList}
                                        id="id"
                                        statusKey="status"
                                        statusObject={ADMIT_STATUS}
                                        btnData={btnData}
                                   />
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
