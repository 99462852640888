import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminRoutes from './components/main/AdminRoutes';
import Login from './components/auth/Login';
import LoadingBar from 'react-top-loading-bar';
import { setLoadingFunctions } from './http/HttpRequest';
import { useRef, useState, useEffect } from 'react';

function App() {

  const topLoadingBarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setLoadingFunctions({
      startLoading: () => {
        setIsLoading(true);
        if (topLoadingBarRef.current) {
          topLoadingBarRef.current.continuousStart();
        }
      },
      completeLoading: () => {
        setIsLoading(false);
        if (topLoadingBarRef.current) {
          topLoadingBarRef.current.complete();
        }
      }
    });
  }, []);

  return (
    <>
      <LoadingBar ref={topLoadingBarRef} color="#393185" />
      {isLoading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 9999,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
        </div>
      )}
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/main/*" element={<AdminRoutes />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
