import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';


export default function ContentHeader(props) {   
    const breadcrumb = { border: "none", float: "right" };
    const renderNewButton = () => {
        if (props.newButton && props.newButtonLink) {
            if (props.modal) {
                return (
                    <Button className='btn btn-sm btn-primary ml-2' onClick={(e) => openModal(props.modalName)} >
                        {props.newButton}
                    </Button>
                );
            } else {
                return (
                    <Link className='btn btn-sm btn-primary ml-2' to={`/${props.newButtonLink}`}>
                        {props.newButton} {/* Translate the button label */}
                    </Link>
                );
            }
        }
        return null;
    };

    const openModal = (modalName) => {       
    };




    return (
        <>
            <section className="content-header slide-in">
                <div className="w-100">
                    <div className="row ">
                        <div className="col-sm-6 demoClass">
                            <h3 className='font-weight-bold'>
                                {props.title}
                                {renderNewButton()}
                            </h3>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-end">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb" style={breadcrumb}>
                                    <li className="breadcrumb-item">
                                        <Link to={"/main"}>Dashboard</Link>
                                    </li>
                                    {props.subTitle && props.subTitleLink && (
                                        <li className="breadcrumb-item">
                                            <Link to={`/${props.subTitleLink}`}>{props.subTitle}</Link>
                                        </li>
                                    )}
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {props.title}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>               
            </section>
        </>
    );
}
