// ExportToExcel.js
import React from 'react';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ data, filename }) => {
  const exportToExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    URL.revokeObjectURL(url);
  };

  return <button onClick={exportToExcel} className='btn btn-sm btn-success' style={{height:"2rem"}}><i className="mdi mdi-file-excel"></i></button>;
};

export default ExportToExcel;
