import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import { useParams } from 'react-router-dom';
import { PAYMENT_DETAILS } from '../../../data/url';
import Alert from '../../../feature/Alert';
import { generatePDF } from '../../../data/GeneratePDF';
import httpClient from '../../../http/HttpRequest';
import { useReactToPrint } from 'react-to-print';
import InvoiceAddress from '../invoice/InvoiceAddress';
import InvoiceHeader from '../invoice/InvoiceHeader';
import Helper from '../../../helper/Helper';
import PaymentTable from './PaymentTable';
import InvoiceFooter from '../invoice/InvoiceFooter';

export default function ViewPayment() {

     const { id } = useParams();

     const [payment, setPayment] = useState({});
     const [hospital, setHospital] = useState({});
     const [patient, setPatient] = useState({});    

     useEffect(() => {
          getPaymentDetails();
     }, []);

     const makePdf = () => {
          let filename = `${payment.number}-PAYMENT`;
          generatePDF(filename);
     };

     const componentRef = React.useRef();

     const handlePrint = useReactToPrint({
          content: () => componentRef.current          
     });

     const getPaymentDetails = async (e) => {
          const res = await httpClient.post(PAYMENT_DETAILS, { id: id });
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    setPayment(result.data.payment);                 
                    setPatient(result.data.patient);
                    setHospital(result.data.hospital);
               } else {
                    Alert.error(result.message);
               }
          } else {
               Alert.error(res.message);
          }
     }

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Payment Receipt" subTitle="Payment" subTitleLink="main/payment" />
                    <div className="col-lg-12 m-auto grid-margin stretch-card">
                         <div className="card">
                              <div style={{width:"90%"}} className="card-body print_section m-auto" id="print_section" ref={componentRef}>
                                   <InvoiceHeader number={payment.number} title="payment_receipt" label='Payment Receipt' />
                                   <InvoiceAddress patient={patient} order={payment} hospital={hospital} label='To' title="payment_receipt" />
                                   <PaymentTable payment={payment} />    
                                   <InvoiceFooter hospital={hospital} title="payment_receipt" />
                                   <div className='text-center mt-5'>
                                        <span  className='text-primary'>This is a system-generated receipt. Signature not required</span>
                                   </div>                                                     
                              </div>                              
                              <div className='text-center'>                                  
                                   <button className="btn btn-sm btn-danger btn-icon-text m-1" onClick={makePdf}>PDF</button>
                                   <button className="btn btn-sm btn-info btn-icon-text m-1" onClick={handlePrint}>PRINT</button>
                              </div>
                         </div>
                    </div>                    
               </div>
          </AdminLayout>
     )
}
