import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import { CHARGES_LIST } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import TableLayout from '../layout/TableLayout';

export default function Charges() {

     const [chargesList, setChargesList] = useState([]);

     const tableHeaders = ['#', 'Item', 'Charges', 'Action'];

     const keys = ["item_name", "unit_price", "btnEdit"];

     const btnData = {
          btnEdit: { name: "Edit", link: "/main/setting/editCharges" },
     }

     useEffect(() => {
          getCharges();
     }, []);

     const getCharges = async (payload = {}) => {
          const res = await httpClient.post(CHARGES_LIST, payload);
          if (res.requestStatus == "OK") {
               const result = res.result;
               if (result.status == 1) {
                    setChargesList(result.data);
               }
          }
     }

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Charges" newButton="New" newButtonLink="main/setting/addCharges" />
                    <div className="col-lg-12 grid-margin stretch-card">
                         <div className="card">
                              <div className="card-body">
                                   <TableLayout
                                        headers={tableHeaders}
                                        keys={keys}
                                        tableData={chargesList}
                                        id="charge_id"
                                        btnData={btnData}
                                   />
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
