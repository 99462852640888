import React, { useEffect, useState } from 'react';
import AdminLayout from '../AdminLayout';
import ContentHeader from '../layout/ContentHeader';
import Helper from '../../../helper/Helper';
import httpClient from '../../../http/HttpRequest';
import { GET_PAYMENT_NUMBER, REFUND_PAYMENT } from '../../../data/url';
import SelectPatient from '../layout/SelectPatient';
import { PAYMENT_MODE } from '../../../data/constant';
import Alert from '../../../feature/Alert';
import { useNavigate } from 'react-router-dom';


export default function RefundPayment() {

     const initialOrderDetails = {
          number: '',
          payment_date: Helper.getToday(),
          mode: '',
          reference: '',
          remark: '',
          charges: '',
          patient_id: ''
     };
     const [orderDetails, setOrderDetails] = useState(initialOrderDetails);

     const [validationErrors, setValidationErrors] = useState({});

     const navigate = useNavigate();

     const getPatient = (data) => {
          const order = { ...orderDetails };
          order['patient_id'] = data.patient_id;
          setOrderDetails(order);
          validateField('patient_id', data.patient_id);
     }

     const handleInputChange = (fieldName, event) => {
          const value = event.target.value;
          const order = { ...orderDetails };
          order[fieldName] = value;
          setOrderDetails(order);
          validateField(fieldName, value);
     };

     const validateField = (fieldName, value) => {
          let errors = { ...validationErrors };
          switch (fieldName) {
               case 'number':
                    errors[fieldName] = value ? '' : 'Payment number is required';
                    break;
               case 'payment_date':
                    errors[fieldName] = value ? '' : 'Payment date is required';
                    break;
               case 'amount':
                    errors[fieldName] = value ? '' : 'Amount is required';
                    break;
               case 'mode':
                    errors[fieldName] = value ? '' : 'Payment mode is required';
                    break;
               case 'patient_id':
                    errors[fieldName] = value ? '' : 'Select Patient';
                    break;
               default:
                    break;
          }
          setValidationErrors(errors);
     }

     useEffect(() => {
          getPaymentNumber();
     }, [])

     const getPaymentNumber = async (e) => {
          const res = await httpClient.post(GET_PAYMENT_NUMBER, {});
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    setOrderDetails(prevOrderDetails => ({
                         ...prevOrderDetails,
                         number: result.number || ''
                    }));
               }
          }
     }

     const savePayment = async () => {
          const fieldsToValidate = ['number', 'payment_date', 'amount', 'mode', 'patient_id'];
          let isValid = true;
          fieldsToValidate.forEach(field => {
               validateField(field, orderDetails[field]);
               if (!orderDetails[field]) {
                    isValid = false;
               }
          });
          if (isValid) {
               const res = await httpClient.post(REFUND_PAYMENT, orderDetails);
               if (res.requestStatus === "OK") {
                    const result = res.result;
                    if (result.status === 1) {
                         Alert.success(result.message);
                         navigate('/main/payment');
                    } else {
                         Alert.failure(result.message);
                    }
               }
          } else {
               Alert.warning('Filled mandetory fields!');
          }
     }

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Refund Payment" subTitle="Payment" subTitleLink="main/payment" />
                    <div className="col-lg-8 m-auto grid-margin stretch-card">
                         <div className='card'>
                              <div className='card-body'>
                                   <div className='row'>
                                        <div className='col-md-12'>
                                             <SelectPatient remitPatient={getPatient} />
                                             {validationErrors.patient_id && <small className="text-danger">{validationErrors.patient_id}</small>}
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                             <label className='small' htmlFor='number'>Payment Number</label>
                                             <input id='number' value={orderDetails.number} type='text' name='number' className='form-control form-control-sm' onChange={(e) => handleInputChange('number', e)} />
                                             {validationErrors.number && <small className="text-danger">{validationErrors.number}</small>}
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                             <label className='small' htmlFor='payment_date'>Payment Date</label>
                                             <input id='payment_date' value={orderDetails.payment_date} type='date' name='payment_date' className='form-control form-control-sm' onChange={(e) => handleInputChange('payment_date', e)} />
                                             {validationErrors.payment_date && <small className="text-danger">{validationErrors.payment_date}</small>}
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                             <label className='small' htmlFor='amount'>Amount</label>
                                             <input id='amount' value={orderDetails.amount} type='number' name='amount' className='form-control form-control-sm' onChange={(e) => handleInputChange('amount', e)} />
                                             {validationErrors.amount && <small className="text-danger">{validationErrors.amount}</small>}
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                             <label className='small' htmlFor='reference'>Reference</label>
                                             <input id='reference' value={orderDetails.reference} type='text' name='reference' className='form-control form-control-sm' onChange={(e) => handleInputChange('reference', e)} />
                                             {validationErrors.reference && <small className="text-danger">{validationErrors.reference}</small>}
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                             <label className='small' htmlFor='charges'>Charges</label>
                                             <input id='charges' value={orderDetails.charges} type='number' name='charges' className='form-control form-control-sm' onChange={(e) => handleInputChange('charges', e)} />
                                             {validationErrors.charges && <small className="text-danger">{validationErrors.charges}</small>}
                                        </div>
                                        <div className='col-md-4 mt-3'>
                                             <label className='small' htmlFor='mode'>Mode</label>
                                             <select id='mode' name='mode' className='form-control form-control-sm' onChange={(e) => handleInputChange('mode', e)}>
                                                  <option value=''>Select</option>
                                                  {Object.entries(PAYMENT_MODE).map(([key, value]) => (
                                                       <option key={key} value={key}>
                                                            {value}
                                                       </option>
                                                  ))}
                                             </select>
                                             {validationErrors.mode && <small className="text-danger">{validationErrors.mode}</small>}
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                             <label className='small' htmlFor='remark'>Remark</label>
                                             <textarea id='remark' value={orderDetails.remark} name='remark' className='form-control form-control-sm' onChange={(e) => handleInputChange('remark', e)}></textarea>
                                             {validationErrors.remark && <small className="text-danger">{validationErrors.remark}</small>}
                                        </div>
                                        <div className='col-md-6 mt-3'>
                                             <button onClick={savePayment} style={{ marginTop: "40px" }} className='btn btn-sm btn-danger auto_click'>SAVE PAYMENT</button>
                                        </div>
                                   </div>
                                   <hr />
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
