import React from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

export default function Nav() {

  const location = useLocation();
  const isActive = (path) => location.pathname.startsWith(path);

  let sideBarStatus = localStorage.getItem("rightSidebar");
  const siderBarClass = sideBarStatus === null || sideBarStatus === 'close' ? "" : "active";
  return (
    <nav className={`sidebar sidebar-offcanvas ${siderBarClass}`} id="sidebar">
      <ul className="nav">
        <NavLink className="nav-item" to={"/main"} end>
          <span className="nav-link" >
            <i className="icon-grid menu-icon"></i>
            <span className="menu-title">Dashboard</span>
          </span>
        </NavLink>
        <Link className={isActive('/main/patients') ? 'nav-item active' : 'nav-item'} to={"/main/patients"}>
          <span className="nav-link" >
            <i className="ti-user menu-icon"></i>
            <span className="menu-title">Patients</span>
          </span>
        </Link>
        <Link className={isActive('/main/admission') ? 'nav-item active' : 'nav-item'} to={"/main/admission"}>
          <span className="nav-link" >
            <i className="ti-id-badge menu-icon"></i>
            <span className="menu-title">Admissions</span>
          </span>
        </Link>
        <Link className={isActive('/main/payment') ? 'nav-item active' : 'nav-item'} to={"/main/payment"}>
          <span className="nav-link" >
            <i className="ti-money menu-icon"></i>
            <span className="menu-title">Payment</span>
          </span>
        </Link>
        <Link className={isActive('/main/invoice') ? 'nav-item active' : 'nav-item'} to={"/main/invoice"}>
          <span className="nav-link" >
            <i className="ti-agenda menu-icon"></i>
            <span className="menu-title">Invoices</span>
          </span>
        </Link>
        <Link className={isActive('/main/setting/charges') ? 'nav-item active' : 'nav-item'} to={"/main/setting/charges"}>
          <span className="nav-link" >
            <i className="ti-money menu-icon"></i>
            <span className="menu-title">Charges </span>
          </span>
        </Link>
        <Link className={isActive('/main/setting/profile') ? 'nav-item active' : 'nav-item'} to={"/main/setting/profile"}>
          <span className="nav-link" >
            <i className="ti-user menu-icon"></i>
            <span className="menu-title">Profile </span>
          </span>
        </Link>
        <Link className={isActive('/main/setting/changePassword') ? 'nav-item active' : 'nav-item'} to={"/main/setting/changePassword"}>
          <span className="nav-link" >
            <i className="ti-settings menu-icon"></i>
            <span className="menu-title">Change Password</span>
          </span>
        </Link>
      </ul>
    </nav>
  )
}
