import React, { useState,useEffect } from 'react'
import Header from './layout/Header';
import Footer from './layout/Footer';
import Nav from './layout/Nav';
import { Navigate } from 'react-router-dom';
import { LOGOUT_TIME } from '../../data/constant';
import Alert from '../../feature/Alert';

export default function AdminLayout({ children }) {
     const [isLoggedIn, setIsLoggedIn] = useState(true);

     useEffect(() => {
          let timer;
          const resetTimer = () => {
               clearTimeout(timer);
               timer = setTimeout(logoutUser, LOGOUT_TIME);
          };

          const logoutUser = () => {
               setIsLoggedIn(false);
               clearTimeout(timer);
               localStorage.removeItem('token');
               localStorage.removeItem('user_id');
               Alert.success('Logout Success');
          };

          const handleUserActivity = () => {
               resetTimer();
          };

          resetTimer();

          document.addEventListener('mousemove', handleUserActivity);
          document.addEventListener('keydown', handleUserActivity);

          return () => {
               document.removeEventListener('mousemove', handleUserActivity);
               document.removeEventListener('keydown', handleUserActivity);
               clearTimeout(timer);
          };
     }, []);


     return (
          <>
               {
                    isLoggedIn ? (
                         <div className="container-scroller" id="sharad" >
                              <Header />
                              <div className="container-fluid page-body-wrapper">
                                   <Nav />
                                   <div className="main-panel"  >
                                        {children}
                                   </div>
                                   <Footer />
                              </div>
                         </div>
                    ) : (
                         <Navigate to="/login" replace />
                    )
               }
          </>
     )
}
