import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import { CHARGES_LIST } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import Alert from '../../../feature/Alert';
import Helper from '../../../helper/Helper';

export default function InvoiceCal(props) {

     const { onSubmit, patient, editItems, notes,order } = props;

     const emptyRow = { item: null, desc: '', quantity: '', price: '', total: '' };
     const [defualtRow, setDefualtRow] = useState(emptyRow);
     const [rows, setRows] = useState([]);

     const [items, setItems] = useState([]);
     const [itemOption, setItemOption] = useState({});

     const [errors, setErrors] = useState({});
     const [editIndex, setEditIndex] = useState(-1);
     const [commanData, setCommanData] = useState({ terms_conditions: '', private_notes: '' });
     const [totals, setTotal] = useState({ net_amount: 0, total_amount: 0, discount_amount: 0, discount_type: 1, discount: 0, paidAmount: 0, pending_amount: 0, excessAmount: 0 });

     const btnStyle = { padding: "5px", borderRadius: "10px", margin: "3px" };
     const thStyle = { lineHeight: "1.5", verticalAlign: "center" };

     const [addDiscountAll, setAddDiscountAll] = useState(false);

     useEffect(() => {
          getChargesList();
     }, []);

     useEffect(() => {
          if (editItems && editItems.length > 0) {
               setRows(editItems);
          }
     }, [editItems]);

     useEffect(() => {
          if (order) {
               setCommanData({ terms_conditions: notes.terms_conditions, private_notes: notes.private_notes });
               setTotal(prevTotal => ({
                    ...prevTotal,                   
                    paidAmount: Helper.formatNumber(order.paid_amount),
                    pending_amount: Helper.formatNumber(order.pending_amount),
                    excessAmount: Helper.formatNumber(order.excess_amount),
                    discount_type: parseInt(order.discount_type),
                    discount: parseFloat(order.discount)
               }));
               if (parseFloat(order.discount) > 0){
                    setAddDiscountAll(true);
               }
          }
     }, [notes,order]);

     useEffect(() => {
          const discount = totals.discount;
          const discount_type = totals.discount_type;
          calculations(discount, discount_type);
     }, [rows, order,patient]);

     const getChargesList = async () => {
          const res = await httpClient.post(CHARGES_LIST, {});
          if (res.requestStatus === 'OK') {
               const result = res.result;
               if (result.status === 1) {
                    setItems(result.data);
                    let options = [];
                    result.data.forEach((row, index) => {
                         options.push({ label: row.item_name, value: row.charge_id });
                    });
                    setItemOption(options);
               } else {
                    Alert.error(res.message);
               }
          }
     }

     const onItemChange = (selectedOption) => {
          const qty = 1;
          const updatedRow = { ...defualtRow };
          updatedRow.item = selectedOption;
          const charge_id = selectedOption.value;
          const itemDetails = findCharges(charge_id);
          updatedRow.price = itemDetails.unit_price;
          updatedRow.quantity = qty;
          const pricing = calculatePrice(itemDetails.unit_price, qty);
          if (pricing) {
               updatedRow.total = pricing.total;
          }
          setDefualtRow(updatedRow);
     };

     const calculatePrice = (p, quantity) => {
          let price = Helper.convertToDecimal(p);
          if (price) {
               let qty = Helper.convertToDecimal(quantity);
               if (qty) {
                    let total = price * qty;
                    return {
                         total: Helper.formatNumber(total)
                    }
               } else {
                    Alert.error('Invalid Quantity');
               }
          } else {
               Alert.error('Invalid Price');
          }
     }

     const calculations = (discount = 0, discount_type = 0) => {
          let total_amount = 0;
          let net_amount = 0;
          let discount_amount = 0;

          rows.forEach((row) => {
               total_amount += parseFloat(row.total);
          });

          if (discount > 0 && discount_type > 0) {
               discount = Helper.convertToDecimal(discount);
               if (discount_type == 1) {
                    discount_amount = total_amount * (discount / 100);
               } else {
                    discount_amount = discount;
               }
          }
          net_amount = total_amount - discount_amount;
          let pending_amount = 0;
          let excessAmount = 0;
          let advance = 0;
          if (order){               
               advance = Helper.convertToDecimal(order.paid_amount);
               if (net_amount >= advance) {
                    pending_amount = net_amount - advance;
               } else {
                    excessAmount = advance - net_amount;
               }
          }else if (patient) {              
               advance = Helper.convertToDecimal(patient.advance_payment);
               if (net_amount >= advance) {
                    pending_amount = net_amount - advance;
               } else {
                    excessAmount = advance - net_amount;
               }
          }
          setTotal(prevTotal => ({
               ...prevTotal,
               total_amount: Helper.formatNumber(total_amount),
               net_amount: Helper.formatNumber(net_amount),
               discount_amount: Helper.formatNumber(discount_amount),
               paidAmount: Helper.formatNumber(advance),
               pending_amount: Helper.formatNumber(pending_amount),
               excessAmount: Helper.formatNumber(excessAmount)
          }));
     }

     const findCharges = (charge_id) => {
          const chargeDetails = items.find(element => element.charge_id === charge_id);
          return chargeDetails;
     };

     const handleInputChange = (fieldName, event) => {
          const value = event.target.value;
          const updatedRow = { ...defualtRow };
          updatedRow[fieldName] = value;
          setDefualtRow(updatedRow);
          if (fieldName === 'price' || fieldName === 'quantity') {
               onPriceChange(updatedRow);
          }
     };

     const onPriceChange = (updatedRows) => {
          let pricing = calculatePrice(updatedRows.price, updatedRows.quantity);
          if (pricing) {
               updatedRows.total = pricing.total;
          }
          setDefualtRow(updatedRows);
     }

     const handleAddRow = () => {
          if (validateField()) {
               setRows((prevRows) => [...prevRows, defualtRow]);
               setDefualtRow(emptyRow);
               const discount = totals.discount;
               const discount_type = totals.discount_type;
               calculations(discount, discount_type);
          }
     };

     const handleUpdateRow = () => {
          const updatedRows = [...rows];
          updatedRows[editIndex] = { ...defualtRow };
          setRows(updatedRows);
          setEditIndex(-1);
          setDefualtRow(emptyRow);
          const discount = totals.discount;
          const discount_type = totals.discount_type;
          calculations(discount, discount_type);
     }

     const handleRemoveRow = (indexToRemove) => {
          const updatedRows = rows.filter((_, index) => index !== indexToRemove);
          setRows(updatedRows);
          const discount = totals.discount;
          const discount_type = totals.discount_type;
          calculations(discount, discount_type);
     };

     const handleEditRow = (index) => {
          setEditIndex(index);
          const editedRow = rows[index];
          let pricing = calculatePrice(editedRow.price, editedRow.quantity);
          if (pricing) {
               editedRow.total = pricing.total;
          }
          setDefualtRow(editedRow);
     };

     const handleCancel = () => {
          setEditIndex(-1);
          setErrors({});
          setDefualtRow(emptyRow);
     }

     const handleCommanData = (fieldName, event) => {
          const value = event.target.value;
          const updatedRow = { ...commanData };
          updatedRow[fieldName] = value;
          setCommanData(updatedRow);
     };


     const validateField = () => {
          let formErrors = {};
          let valid = true;
          if (defualtRow.item === null) {
               formErrors.item = 'Select Item';
               valid = false;
          }
          if (defualtRow.price === '') {
               formErrors.price = 'Charges are required';
               valid = false;
          }
          if (isNaN(Number(defualtRow.price))) {
               formErrors.price = 'Charges should be numeric';
               valid = false;
          }
          if (defualtRow.quantity === '') {
               formErrors.quantity = 'Quantity is required';
               valid = false;
          }
          if (isNaN(Number(defualtRow.quantity))) {
               formErrors.quantity = 'Quantity should be numeric';
               valid = false;
          }
          setErrors(formErrors);
          return valid;
     }

     const handleAllDiscountCheckbox = (event) => {
          setAddDiscountAll(event.target.checked);
          setTotal(prevTotal => ({
               ...prevTotal,
               discount: 0,
               discount_type: 1
          }));
          applyAllDiscount(0, 0);
     };

     const applyAllDiscount = (discount, discount_type) => {
          calculations(discount, discount_type);
     }

     const handleAllDiscount = (event) => {
          const discount = event.target.value;
          setTotal(prevTotal => ({
               ...prevTotal,
               discount: discount
          }));
          const discount_type = totals.discount_type;
          applyAllDiscount(discount, discount_type);
     }

     const handleAllDiscountTypeChange = (event) => {
          const t = event.target.value;
          setTotal(prevTotal => ({
               ...prevTotal,
               discount_type: t
          }));
          const discount = totals.discount;
          applyAllDiscount(discount, t);
     }

     const saveOrder = () => {
          onSubmit(rows, commanData, totals);
     }

     return (
          <>
               <div className='row'>
                    <div className='col-md-3'>
                         <label className='small'>Item</label>
                         <Select
                              value={defualtRow.item}
                              options={itemOption}
                              isSearchable={true}
                              placeholder="Select"
                              id='charge_id'
                              onChange={(selectedOption) => onItemChange(selectedOption)}
                         />
                         {errors.item && <p style={{ color: "red" }}>{errors.item}</p>}
                         <textarea
                              className='form-control form-control-sm mt-1'
                              placeholder="Desc"
                              id='desc'
                              onChange={(e) => handleInputChange('desc', e)}
                              value={defualtRow.desc}></textarea>
                    </div>
                    <div className='col-md-2'>
                         <label className='small' htmlFor='price'>Price</label>
                         <input
                              id='price'
                              type='number'
                              className='form-control form-control-sm'
                              value={defualtRow.price}
                              onChange={(e) => handleInputChange('price', e)}
                         />
                         {errors.price && <p style={{ color: "red" }}>{errors.price}</p>}
                    </div>
                    <div className='col-md-2'>
                         <label className='small' htmlFor='quantity'>Quantity</label>
                         <input
                              id='quantity'
                              type='number'
                              className='form-control form-control-sm'
                              value={defualtRow.quantity}
                              onChange={(e) => handleInputChange('quantity', e)}
                         />
                         {errors.quantity && <p style={{ color: "red" }}>{errors.quantity}</p>}
                    </div>

                    <div className='col-md-2'>
                         <label className='small' htmlFor='total'>Total</label>
                         <input
                              id='total'
                              type='number'
                              className='form-control form-control-sm'
                              value={defualtRow.total}
                              disabled
                         />
                    </div>
                    <div className='col-md-3' style={{ paddingTop: "15px" }}>
                         {
                              editIndex === -1 ? (

                                   <button onClick={() => handleAddRow()} className='btn btn-primary m-2 auto_click'>
                                        Add
                                   </button>
                              ) : (
                                   <button onClick={() => handleUpdateRow()} className='btn btn-primary m-2'>
                                        Update
                                   </button>
                              )
                         }
                         <button onClick={() => handleCancel()} className='btn btn-danger m-2'>
                              Cancel
                         </button>
                    </div>
               </div>
               <div className='mt-2'>
                    <div className='table-responsive'>
                         <table className='table table-striped table-sm table-bordered table-hovered'>
                              <thead>
                                   <tr className='text-center' >
                                        <th style={thStyle} >#</th>
                                        <th style={thStyle}>Item</th>
                                        <th style={thStyle}>Quantity</th>
                                        <th style={thStyle}>Price</th>
                                        <th style={thStyle}>Total</th>
                                        <th style={thStyle}>Action</th>
                                   </tr>
                              </thead>
                              <tbody className='pt-2'>
                                   {
                                        rows.map((row, index) => (
                                             <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td style={{ width: "200px" }}>{row.item.label} <br /><small>{row.desc}</small></td>
                                                  <td className='text-right'>{row.quantity}</td>
                                                  <td className='text-right'>{row.price}</td>
                                                  <td className='text-right'>{row.total}</td>
                                                  <td className='text-center'>
                                                       <button onClick={() => handleEditRow(index)} className='btn btn-sm btn-primary' style={btnStyle}>
                                                            <i className='mdi mdi-lead-pencil'></i>
                                                       </button>
                                                       <button onClick={() => handleRemoveRow(index)} className='btn btn-sm btn-danger' style={btnStyle}>
                                                            <i className='mdi mdi-minus-box'></i>
                                                       </button>
                                                  </td>
                                             </tr>
                                        ))
                                   }
                              </tbody>
                         </table>
                    </div>
               </div>
               <hr />
               <div className='row mt-3'>
                    <div className='col-md-8'>
                         <div className='row'>
                              <div className='col-md-6'>
                                   <div className="form-check form-check-primary">
                                        <label className="form-check-label">
                                             <input id="add_discount_all" type="checkbox" className="form-check-input" checked={addDiscountAll} onChange={handleAllDiscountCheckbox} />
                                             Add Discount
                                             <i className="input-helper"></i></label>
                                   </div>
                              </div>
                              <div className='col-md-4'>
                                   {addDiscountAll && <input type='number' className='form-control form-control-sm' value={totals.discount} onChange={handleAllDiscount} />}
                              </div>
                              <div className='col-md-2'>
                                   {addDiscountAll &&
                                        <select className='form-control form-control-sm' value={totals.discount_type} onChange={handleAllDiscountTypeChange}>
                                             <option value={1}>%</option>
                                             <option value={2}>Fixed</option>
                                        </select>
                                   }
                              </div>
                         </div>
                         <div className='row'>
                              <div className='col-md-6'>
                                   <label className='small' htmlFor='terms_conditions'>Terms & Conditions</label>
                                   <textarea className='form-control form-control-sm' id='terms_conditions' value={commanData.terms_conditions} onChange={(e) => handleCommanData('terms_conditions', e)}></textarea>
                              </div>
                              <div className='col-md-6'>
                                   <label className='small' htmlFor='private_notes'>Private Notes</label>
                                   <textarea className='form-control form-control-sm' id='private_notes' value={commanData.private_notes} onChange={(e) => handleCommanData('private_notes', e)}></textarea>
                              </div>
                         </div>
                    </div>
                    <div className='col-md-4 '>
                         <table className='table table-sm table-borderless'>
                              <tbody>
                                   <tr>
                                        <td style={thStyle}>Total Amount</td>
                                        <td style={thStyle} className='text-right'>{totals.total_amount}</td>
                                   </tr>
                                   <tr>
                                        <td style={thStyle}>Discount</td>
                                        <td style={thStyle} className='text-right'>{totals.discount_amount}</td>
                                   </tr>
                                   <tr>
                                        <td style={thStyle} className='text-success'>Net Amount</td>
                                        <td style={thStyle} className='text-right text-success'>{totals.net_amount}</td>
                                   </tr>
                                   {
                                        totals.paidAmount > 0 &&
                                        <tr>
                                             <td style={thStyle} className='text-success'>Paid Amount</td>
                                             <td style={thStyle} className='text-right text-success'>{totals.paidAmount}</td>
                                        </tr>
                                   }
                                   {
                                        totals.pending_amount > 0 &&
                                        <tr>
                                             <td style={thStyle} className='text-danger'>Pending Amount</td>
                                             <td style={thStyle} className='text-right text-danger'>{totals.pending_amount}</td>
                                        </tr>
                                   }
                                   {
                                        totals.excessAmount > 0 &&
                                        <tr>
                                             <td style={thStyle} className='text-infor'>Excess Amount</td>
                                             <td style={thStyle} className='text-right text-success'>{totals.excessAmount}</td>
                                        </tr>
                                   }
                              </tbody>
                         </table>
                    </div>
               </div>
               <div className='text-center mt-2'>
                    <button className='btn btn-primary auto_click' onClick={saveOrder}>SAVE</button>
               </div>
          </>
     )
}
