import React from 'react';
import { DARK_COLOR, HOSPITAL } from '../../../data/constant';
import logo from '../../../logo.png';
import logo192 from '../../../logo192.png'; // Assuming you have another logo

export default function InvoiceHeader(props) {
     const { label } = props;

     const printStyles = {
          container: {
               paddingBottom: '10px',
               marginTop: '3rem',
          },
          row: {
               display: 'flex',
               // alignItems: 'center',
               // justifyContent: 'space-between',
               paddingBottom: '10px',
          },
          col: {
               display: 'block',
               // justifyContent: 'center',
               // alignItems: 'center',
          },
          img: {
               // display: 'block',
               // margin: '0 auto',
          },
          h4: {
               marginTop: '30px',
               // textAlign: 'right',
          },
          h5: {
               textAlign: 'center',
               marginTop: '20px',
               paddingBottom: '20px',
               borderBottom: `1px solid ${DARK_COLOR}`,
          }
     };

     return (
          <div style={printStyles.container}>
               <div style={printStyles.row}>
                    <div className='col-md-6' style={printStyles.col}>
                         <img src={logo} style={{ ...printStyles.img, width: "40%" }} alt="logo" />
                         <br/>
                         <h2 style={printStyles.h4}>
                              {HOSPITAL}
                         </h2>
                    </div>
                    <div className='col-md-6' style={{ ...printStyles.col, textAlign: 'right' }}>
                         <img src={logo192} style={{ width: "30%" }} alt="logo" />
                    </div>                   
               </div>

               <h3 style={printStyles.h5}>
                    {label}
               </h3>
          </div>
     );
}
