import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const LineChart = ({ data }) => {
     const chartRef = useRef(null);
     const chartInstance = useRef(null);

     useEffect(() => {
          if (chartRef && chartRef.current && data) {
               if (chartInstance.current) {
                    chartInstance.current.destroy();
               }

               const ctx = chartRef.current.getContext('2d');
               chartInstance.current = new Chart(ctx, {
                    type: 'line',
                    data: {
                         labels: data.labels,
                         datasets: [
                              {
                                   label: 'Invoice',
                                   data: data.invoice,
                                   fill: false,
                                   borderColor: '#4B49AC',
                                   tension: 0.1,
                              },
                              {
                                   label: 'Paid Amount',
                                   data: data.paid,
                                   borderColor: '#FF6384',
                                   backgroundColor: '#FFB1C1',
                              }
                         ],
                    },
                    options: {
                         scales: {
                              y: {
                                   beginAtZero: true,
                              },
                         },
                    },
               });
          }
     }, [data]);

     return <canvas ref={chartRef} />;
};

export default LineChart;
