export const EXCLUDE_URL = [
     "user/login"
]

export const HOSPITAL = 'SAI SEVA BALRUGNALAYA'

export const API_URL = 'https://sai.pnsinfotech.in/rest/';

export const IMAGE_PATH = 'https://sai.pnsinfotech.in/rest/uploads/';

export const MAX_RESULTS = 10;

export const LOGOUT_TIME = 1000 * 60 * 24; //replace last value with how many minutes

export const PATIENT_STATUS = {
     1: {
          name: "ACTIVE", colorClass: "text-success"
     },
     2: {
          name: "DEACTIVE", colorClass: "text-danger"
     }
}

export const ADMIT_STATUS = {
     1: {
          name: "ADMITED", colorClass: "text-success"
     },
     2: {
          name: "DISCHARGE", colorClass: "text-danger"
     }
}

export const PAYMENT_MODE = {
     1: "CASH",
     2: "UPI",
     3: "NET BANKING",
     4: "CREDIT CARD",
     5: "DEBIT CARD",
     6: "CHEQUE",
     7: "CREDIT NOTE",
     8: "OTHER"
}


export const ORDER_STATUS = {
     1: {
          name: "PROCESSING", colorClass: "text-info"
     },
     2: {
          name: "COMPLETE", colorClass: "text-success"
     },
     3: {
          name: "REJECTED", colorClass: "text-danger"
     }
}

export const PAYMENT_STATUS = {
     1: {
          name: "PAID", colorClass: "text-success"
     },
     2: {
          name: "NOT PAID", colorClass: "text-danger"
     },
     3: {
          name: "PARTIAL PAID", colorClass: "text-info"
     }
}

export const PRIMARY_COLOR = '#4B49AC';

export const DARK_COLOR = '#000';

export const checkObject = (obj) => {
     if(Object.keys(obj).length === 0){
          return false;
     } else{
          return true;
     }
}

export const convertTo12HourFormat = (time) => {       
     if (!time) {
          return '';
     }
     const [hours, minutes, seconds] = time.split(':');
     const period = +hours >= 12 ? 'PM' : 'AM';
     const adjustedHours = +hours % 12 || 12; 
     return `${adjustedHours}:${minutes} ${period}`;
}