import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import { Link, useParams } from 'react-router-dom'
import { VIEW_INVOICE } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import InvoiceHeader from './InvoiceHeader';
import InvoiceAddress from './InvoiceAddress';
import InvoiceTable from './InvoiceTable';
import { useReactToPrint } from 'react-to-print';
import { generatePDF } from '../../../data/GeneratePDF';
import InvoiceFooter from './InvoiceFooter';

export default function ViewInvoice() {

     const { id } = useParams();

     const [order, setOrder] = useState({});
     const [details, setOrderDetails] = useState([]);
     const [hospital, setHospital] = useState({});
     const [patient, setPatient] = useState({});
     const [admission, setAdmission] = useState({});

     useEffect(() => {
          getOrderDetails();
     }, []);

     const getOrderDetails = async (e) => {
          const res = await httpClient.post(VIEW_INVOICE, { invoice_id: id });
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    setOrder(result.data.order);
                    setOrderDetails(result.data.details);
                    setPatient(result.data.patient);
                    setHospital(result.data.hospital);
                    setAdmission(result.data.admission);
               }
          }
     }

     const makePdf = () => {
          let filename = `${order.invoice_number}-INVOICE`;
          generatePDF(filename);
     };

     const componentRef = React.useRef();

     const handlePrint = useReactToPrint({
          content: () => componentRef.current
     });

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="View Invoice" subTitle="Invoices" subTitleLink="main/invoice" />
                    <div  className="col-md-12 m-auto grid-margin stretch-card">
                         <div className="card">
                              <div style={{ width: "90%" }} className="m-auto card-body print_section" id="print_section" ref={componentRef}>
                                   <InvoiceHeader label="Invoice" title="bill_to" />
                                   <InvoiceAddress patient={patient} order={order} hospital={hospital} admission={admission}  label='Bill to' title="bill_to" />
                                   <InvoiceTable patient={patient} order={order} details={details} title="bill_to" />
                                   <InvoiceFooter hospital={hospital} title="bill_to" />                                   
                                   <div className='text-center mt-5'>
                                        <span className='text-primary'>This is a system-generated invoice. Signature not required</span>
                                   </div>
                              </div>
                              <div className='text-center mb-2'>
                                   <Link to={`/main/invoice/edit/${id}`} type="button" className="btn btn-sm btn-primary btn-icon-text m-1">
                                        Edit
                                   </Link>
                                   <button className="btn btn-sm btn-danger btn-icon-text m-1" onClick={makePdf}>PDF</button>
                                   <button className="btn btn-sm btn-info btn-icon-text m-1" onClick={handlePrint}>PRINT</button>
                                   <Link className="btn btn-sm btn-success btn-icon-text m-1" to={`/main/invoice/card/${order.invoice_id}`} >DISCHARGE CARD</Link>
                                   
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
