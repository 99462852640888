import React from 'react'
import { UserProvider } from './user/UserContext';
import { Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './dashboard/Dashboard';
import PatientRoutes from './patients/PatientRoutes';
import SettingRoute from './setting/SettingRoute';
import InvoiceRoute from './invoice/InvoiceRoute';
import PaymentRoute from './payment/PaymentRoute';
import AdmissionRoute from './admission/AdmissionRoute';

const isAuthenticated = () => {
     const token = localStorage.getItem('token');
     const isAdminAuthenticated = token ? true : false;
     return isAdminAuthenticated;
};

export default function AdminRoutes() {
     return isAuthenticated() ? (
          <UserProvider>
               <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/patients/*" element={<PatientRoutes />} />
                    <Route path="/setting/*" element={<SettingRoute />} />
                    <Route path="/invoice/*" element={<InvoiceRoute />} />
                    <Route path="/payment/*" element={<PaymentRoute />} />
                    <Route path="/admission/*" element={<AdmissionRoute />} />
               </Routes>
          </UserProvider>
     ) : (
          <Navigate to="/login" replace />
     );
}
