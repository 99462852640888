import html2pdf from 'html2pdf.js';
import Helper from '../helper/Helper';

export const generatePDF = (filename, orientation = 'portrait') => {
     const printSection = document.getElementById('print_section');
     if (printSection) {
          let date = Helper.getToday();
          const opt = {
               margin: 0.25,
               filename: `${filename}-${date}.pdf`,
               image: { type: 'jpeg', quality: 1 }, 
               html2canvas: { scale: 2 }, 
               jsPDF: { unit: 'in', format: 'letter', orientation: orientation }
          };
          html2pdf().from(printSection).set(opt).save();
     }
};