import React from 'react'

export default function Footer() {
  return (
    <footer className="footer" style={{ position: "fixed", bottom: 0, width: "100%", padding: "10px" }}>
      <div className="text-center">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2024 PNS InfoTech</span>
      </div>
    </footer>
  )
}
