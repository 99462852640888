import React, { useState, useEffect } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import TableLayout from '../layout/TableLayout';
import httpClient from '../../../http/HttpRequest';
import { PATIENT_LIST } from '../../../data/url';
import { PATIENT_STATUS } from '../../../data/constant';
import FormLayout from '../layout/FormLayout';

export default function PatientList() {

     const [patientList, setPatientList] = useState([]);

     const tableHeaders = ['#', 'Action', 'Name', 'Mobile', 'Status', 'Advance','Due', 'Reg Date', 'DOB', 'Gender', "Address"];

     const keys = ["btnEdit", "patient_name", "mobile", "status", 'advance_payment','pending_payment', "registration_date", "date_of_birth", "gender","address"];

     const btnData = {
          btnEdit: { name: "Edit", link: "/main/patients/edit" },
     }

     useEffect(() => {
          getPatientList();
     }, []);

     const getPatientList = async (payload = {}) => {
          const res = await httpClient.post(PATIENT_LIST, payload);
          if (res.requestStatus == "OK") {
               const result = res.result;
               if (result.status == 1) {
                    setPatientList(result.data);
               }
          }
     }

     const [formData, setFormData] = useState({
          formTitle: 'Search Customer',
          formFields: [
               { label: 'From Date', id: 'from_date', type: 'date', name: 'from_date', value: '', className: "col-md-2" },
               { label: 'To Date', id: 'to_date', type: 'date', name: 'to_date', value: '', className: "col-md-2" },
               { label: 'Mobile', id: 'mobile', type: 'number', name: 'mobile', value: '', className: "col-md-3", placeholder: 'Enter mobile' },
          ],
          formErrors: { from_date: '', to_date: '', mobile: '' },
     });

     const handleFieldChange = (fieldName, value) => {
          setFormData((prevFormData) => {
               const updatedFormFields = prevFormData.formFields.map((field) =>
                    field.name === fieldName ? { ...field, value: value } : field
               );
               return {
                    ...prevFormData,
                    formFields: updatedFormFields,
               };
          });
     }

     const handleFieldBlur = () => { }

     const handleSubmit = (e) => {
          e.preventDefault();
          let post = {};
          const updatedFormFields = formData.formFields.map((field) => {
               post[field.name] = field.value;
               return field;
          });
          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
          }));
          getPatientList(post);
     }

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Patient List" newButton="New" newButtonLink="main/patients/new" />
                    <div className="col-lg-12 grid-margin stretch-card">
                         <div className="card">
                              <div className="card-body">
                                   <div className='mb-2'>
                                        <FormLayout
                                             formTitle={formData.formTitle}
                                             formFields={formData.formFields}
                                             formErrors={formData.formErrors}
                                             onFieldChange={handleFieldChange}                                           
                                             onSubmit={handleSubmit}
                                             onFieldBlur={handleFieldBlur}                                        
                                             search
                                             isModal
                                        />
                                   </div>
                                   <TableLayout
                                        headers={tableHeaders}
                                        keys={keys}
                                        tableData={patientList}
                                        id="patient_id"
                                        statusKey="status"
                                        statusObject={PATIENT_STATUS}
                                        btnData={btnData}
                                   />
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
