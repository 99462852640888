import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PaymentList from './PaymentList'
import NewPayment from './NewPayment'
import ViewPayment from './ViewPayment'
import RefundPayment from './RefundPayment'

export default function PaymentRoute() {
     return (
          <Routes>
               <Route path="/" element={<PaymentList />} />
               <Route path="/new" element={<NewPayment />} />
               <Route path="/view/:id" element={<ViewPayment />} />
               <Route path="/refund" element={<RefundPayment />} />
          </Routes>
     )
}
