import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import httpClient from '../../../http/HttpRequest';
import { SELECT_PATIENT_LIST } from '../../../data/url';

export default function SelectPatient({ remitPatient, patientId }) {

     const [patientList, setPatientList] = useState([]);
     const [patientData, setPatientData] = useState([]);
     const [selectedPatient, setSelectedPatient] = useState(null);
     const [loading, setLoading] = useState(false);

     useEffect(() => {
          getPatientList();
     }, [])


     const onChange = (selectedOption) => {
          setSelectedPatient(selectedOption);
     };

     const getPatientList = async (input = '') => {
          setLoading(true);
          let payload = { string: input }
          const res = await httpClient.sendRequest(SELECT_PATIENT_LIST, payload);
          setLoading(false);
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    let data = result.data;
                    setPatientData(data);
                    let option = [];
                    data.forEach(element => {
                         option.push({ label: `${element.name} [${element.mobile}]`, value: element.patient_id });
                    });
                    setPatientList(option);
               }
          }
     }

     const handleInputChange = (inputValue, { action }) => {
          if (action === 'input-change') {
               getPatientList(inputValue);
          }
     };

     useEffect(() => {
          setPatientFromProps();
     }, [patientId])

     const setPatientFromProps = () => {
          if (patientId && patientList) {
               const patientFound = patientList.find(element => element.value === patientId);               
               if (patientFound) {
                    setSelectedPatient(patientFound);
               }
          }
     }

     useEffect(() => {
          if (selectedPatient) {
               const patientDetails = patientData.find(element => element.patient_id === selectedPatient.value);
               remitPatient(patientDetails);
          }
     }, [selectedPatient])

     return (
          <div>
               <label className='mb-1 small'>Select Patient
                    <Link className='ml-1 btn btn-sm btn-primary' to="/main/patients/new">New</Link>
               </label>
               <Select
                    autoFocus
                    id='patient_id'
                    value={selectedPatient}
                    options={patientList}
                    isSearchable={true}
                    placeholder="Select Patient"
                    onChange={onChange}
                    onInputChange={handleInputChange}
                    isLoading={loading}
               /></div>
     )
}
