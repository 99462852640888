import React, { useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import { CHANGE_PASS } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import FormLayout from '../layout/FormLayout';
import Alert from '../../../feature/Alert';

export default function ChangePassword() {

     const [directSubmit, setDirectSubmit] = useState(1);
     const [formData, setFormData] = useState({
          formTitle: 'Change Password',
          formFields: [
               { label: 'Old Password', id: 'old_password', type: 'password', name: 'old_password', value: '', required: true, className: "col-md-12" },
               { label: 'New password', id: 'new_password', type: 'password', name: 'new_password', value: '', required: true, className: "col-md-12" },
               { label: 'Confirm Password', id: 'confirm_password', type: 'password', name: 'confirm_password', value: '', required: true, className: "col-md-12" },
          ],
          formErrors: { old_password: '', new_password: '', confirm_password: '' },
     });

     function handleFieldChange(fieldName, value) {
          setDirectSubmit(0);
          setFormData((prevFormData) => {
               const updatedFormFields = prevFormData.formFields.map((field) =>
                    field.name === fieldName ? { ...field, value: value } : field
               );
               return {
                    ...prevFormData,
                    formFields: updatedFormFields,
               };
          });
     }

     function handleFieldBlur(fieldName, value) {
          setDirectSubmit(0);
          validateField(fieldName, value);
     }

     function validateField(fieldName, value) {
          setFormData((prevFormData) => {
               const updatedErrors = { ...prevFormData.formErrors };

               if (fieldName === 'old_password') {
                    updatedErrors.old_password = value.trim() === '' ? 'field_required' : '';
               } else if (fieldName === 'new_password') {
                    updatedErrors.new_password = value.trim() === '' ? 'field_required' : '';
               } else if (fieldName === 'confirm_password') {
                    updatedErrors.confirm_password = value.trim() === '' ? 'field_required' : '';
                    if (formData.formFields[1]['value'] !== formData.formFields[2]['value']) {
                         updatedErrors.confirm_password = "New and Confirm Password Should be Same";
                    }
               }
               return {
                    ...prevFormData,
                    formErrors: updatedErrors,
               };
          });
          return formData.formErrors;

     }

     function handleSubmit(e) {
          e.preventDefault();
          let post = {};
          let hasErrors = false;
          const updatedFormFields = formData.formFields.map((field) => {
               const allError = validateField(field.name, field.value);
               const error = allError[field.name];
               if (error !== '') {
                    hasErrors = true;
               } else {
                    post[field.name] = field.value;
               }
               return field;
          });

          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
          }));
          if (!hasErrors && directSubmit === 0) {
               subtmitData(post);
          }
     }

     const subtmitData = async (post) => {
          const res = await httpClient.post(CHANGE_PASS, post);
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    Alert.success(result.message);
               } else {
                    Alert.failure(result.message);
               }
          }
     }

     function handleReset() {
          const updatedFormFields = formData.formFields.map((field) => ({
               ...field,
               value: '',
          }));

          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
               formErrors: {},
          }));
     }
     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Change Password" />
                    <div className='m-auto col-md-6'>
                         <FormLayout
                              formTitle={formData.formTitle}
                              formFields={formData.formFields}
                              formErrors={formData.formErrors}
                              onFieldChange={handleFieldChange}
                              onFieldBlur={handleFieldBlur}
                              onSubmit={handleSubmit}
                              handleReset={handleReset}
                         />
                    </div>
               </div>
          </AdminLayout>
     )
}
