import React, { useState,useEffect } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import httpClient from '../../../http/HttpRequest';
import Alert from '../../../feature/Alert';
import { GET_PATIENT, SAVE_PATIENT } from '../../../data/url';
import FormLayout from '../layout/FormLayout';
import Helper from '../../../helper/Helper';
import { useNavigate, useParams } from 'react-router-dom';

export default function NewPatient() {
     const { id } = useParams();
     const action = typeof id === 'undefined' ? 'ADD' : 'UPDATE';

     let title = "NEW PATIENT";

     if (action === 'UPDATE') {
          title = "UPDATE PATIENT";
     }

     const navigate = useNavigate();

     const [directSubmit, setDirectSubmit] = useState(1);
     const genderData = [
          { label: 'MALE', value: "Male" },
          { label: 'FEMALE', value: "Female" },
          { label: 'OTHER', value: "Other" }
     ];
     const [formData, setFormData] = useState({
          formTitle: 'Profile',
          formFields: [
               { label: 'Patient Name', id: 'patient_name', type: 'text', name: 'patient_name', value: '', required: true, className: "col-md-6", placeholder: "Enter name" },
               { label: 'Mobile', id: 'mobile', type: 'text', name: 'mobile', value: '', required: true, className: "col-md-3", placeholder: "Enter mobile" },
               { label: 'Date of Birth', id: 'date_of_birth', type: 'date', name: 'date_of_birth', value: '', className: "col-md-3" },
               { label: 'Reg Date', id: 'registration_date', type: 'date', name: 'registration_date', value: Helper.getToday(), required: true, className: "col-md-3" },
               { label: 'Gender', id: 'gender', type: 'select', name: 'gender', value: '', className: "col-md-3", options: genderData },
               { label: 'Address', id: 'address', type: 'textarea', name: 'address', value: '', className: "col-md-6", placeholder: "Enter address" },
          ],
          formErrors: { patient_name: '', mobile: '', date_of_birth: '', registration_date: '', gender: '', age: '', weight: '', address: '' },
     });

     useEffect(() => {
          if (id) {
               getPatient();
          }
     }, [id])

     const getPatient = async () => {
          if (id) {
               const res = await httpClient.post(GET_PATIENT, { patient_id: id });
               if (res.requestStatus === 'OK') {
                    const result = res.result;
                    if (result.status === 1) {
                         setFormData(prevFormData => {
                              const updatedFormFields = prevFormData.formFields.map(field => {                                   
                                   if (field.name in result.data) {
                                        field.value = result.data[field.name];                                        
                                   }
                                   return field;
                              });                           
                              return {
                                   ...prevFormData,
                                   formFields: updatedFormFields,
                              };
                         });
                    } else {
                         Alert.error(res.message);                         
                    }
               }
          }
     };

     useEffect(() => {
          if (action === 'UPDATE') {
               setFormData(prevFormData => {
                    const statusFieldExists = prevFormData.formFields.some(field => field.id === 'status');
                    if (!statusFieldExists) {
                         const newFormField = {
                              label: 'Status', id: 'status', type: 'radio', name: 'status', value: '', className: "col-12", options: [
                                   { label: 'ACTIVE', value: "1", class: "success" },
                                   { label: 'DEACTIVE', value: "2", class: "danger" },
                              ]
                         };
                         const newFormErrors = {
                              ...prevFormData.formErrors,
                              status: ''
                         };
                         const updatedFormFields = [...prevFormData.formFields, newFormField];
                         return {
                              ...prevFormData,
                              formFields: updatedFormFields,
                              formErrors: newFormErrors
                         };
                    }
                    return prevFormData;
               });
          }
     }, [id]);     

     function handleFieldChange(fieldName, value) {
          setDirectSubmit(0);
          setFormData((prevFormData) => {
               const updatedFormFields = prevFormData.formFields.map((field) =>
                    field.name === fieldName ? { ...field, value: value } : field
               );
               return {
                    ...prevFormData,
                    formFields: updatedFormFields,
               };
          });
     }

     function handleFieldBlur(fieldName, value) {
          setDirectSubmit(0);
          validateField(fieldName, value);
     }

     function validateField(fieldName, value) {
          setFormData((prevFormData) => {
               const updatedErrors = { ...prevFormData.formErrors };
               if (fieldName === 'first_name') {
                    updatedErrors.first_name = value.trim() === '' ? 'First name is required' : '';
               } else if (fieldName === 'last_name') {
                    updatedErrors.last_name = value.trim() === '' ? 'Last Name is required' : '';
               } else if (fieldName === 'mobile') {
                    updatedErrors.mobile = value.trim() === '' ? 'Mobile number required' : '';
               }
               return {
                    ...prevFormData,
                    formErrors: updatedErrors,
               };
          });
          return formData.formErrors;

     }

     function handleSubmit(e) {
          e.preventDefault();
          let post = {};
          let hasErrors = false;
          const updatedFormFields = formData.formFields.map((field) => {
               const allError = validateField(field.name, field.value);
               const error = allError[field.name];
               if (error !== '') {
                    hasErrors = true;
               } else {
                    post[field.name] = field.value;
               }
               return field;
          });

          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
          }));
          if (!hasErrors && directSubmit === 0) {
               if (action === 'UPDATE') post['patient_id'] = id;
               subtmitData(post);
          }
     }

     const subtmitData = async (post) => {
          const res = await httpClient.post(SAVE_PATIENT, post);
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    Alert.success(result.message);
                    navigate('/main/patients');
               } else {
                    Alert.failure(result.message);
               }
          }
     }

     function handleReset() {
          const updatedFormFields = formData.formFields.map((field) => ({
               ...field,
               value: '',
          }));
          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
               formErrors: {},
          }));
     }

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title={title} subTitle="Patient List" subTitleLink="main/patients" />
                    <div className='col-md-11 m-auto'>
                         <FormLayout
                              formTitle={formData.formTitle}
                              formFields={formData.formFields}
                              formErrors={formData.formErrors}
                              onFieldChange={handleFieldChange}
                              onFieldBlur={handleFieldBlur}
                              onSubmit={handleSubmit}
                              handleReset={handleReset}
                         />
                    </div>
               </div>
          </AdminLayout>
     )
}
