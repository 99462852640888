import React, { useState,useEffect } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import { useNavigate, useParams } from 'react-router-dom';
import { GET_CHARGES, SAVE_CHARGES } from '../../../data/url';
import Alert from '../../../feature/Alert';
import FormLayout from '../layout/FormLayout';
import httpClient from '../../../http/HttpRequest';

export default function EditCharges() {

     const { id } = useParams();
     const action = typeof id === 'undefined' ? 'ADD' : 'UPDATE';

     let title = "NEW CHARGES";

     if (action === 'UPDATE') {
          title = "UPDATE CHARGES";
     }

     const navigate = useNavigate();
     const [directSubmit, setDirectSubmit] = useState(1);

     const [formData, setFormData] = useState({
          formTitle: 'Profile',
          formFields: [
               { label: 'Item', id: 'item_name', type: 'text', name: 'item_name', value: '', required: true, className: "col-md-12", placeholder: "Enter name" },
               { label: 'Charges', id: 'unit_price', type: 'number', name: 'unit_price', value: '', required: true, className: "col-md-12", placeholder: "Enter charges" },
          ],
          formErrors: { item_name: '', unit_price: '' },
     });

     useEffect(() => {
          if (id) {
               getCharges();
          }
     }, [id])

     const getCharges = async () => {
          if (id) {
               const res = await httpClient.post(GET_CHARGES, { charge_id: id });
               if (res.requestStatus === 'OK') {
                    const result = res.result;
                    if (result.status === 1) {
                         setFormData(prevFormData => {
                              const updatedFormFields = prevFormData.formFields.map(field => {
                                   if (field.name in result.data) {
                                        field.value = result.data[field.name];
                                   }
                                   return field;
                              });
                              return {
                                   ...prevFormData,
                                   formFields: updatedFormFields,
                              };
                         });
                    } else {
                         Alert.error(res.message);
                    }
               }
          }
     };

     function handleFieldChange(fieldName, value) {
          setDirectSubmit(0);
          setFormData((prevFormData) => {
               const updatedFormFields = prevFormData.formFields.map((field) =>
                    field.name === fieldName ? { ...field, value: value } : field
               );
               return {
                    ...prevFormData,
                    formFields: updatedFormFields,
               };
          });
     }

     function handleFieldBlur(fieldName, value) {
          setDirectSubmit(0);
          validateField(fieldName, value);
     }

     function validateField(fieldName, value) {
          setFormData((prevFormData) => {
               const updatedErrors = { ...prevFormData.formErrors };
               if (fieldName === 'item_name') {
                    updatedErrors.item_name = value.trim() === '' ? 'Item name is required' : '';
               } else if (fieldName === 'unit_price') {
                    updatedErrors.unit_price = value.trim() === '' ? 'Charges is required' : '';
               }
               return {
                    ...prevFormData,
                    formErrors: updatedErrors,
               };
          });
          return formData.formErrors;

     }

     function handleSubmit(e) {
          e.preventDefault();
          let post = {};
          let hasErrors = false;
          const updatedFormFields = formData.formFields.map((field) => {
               const allError = validateField(field.name, field.value);
               const error = allError[field.name];
               if (error !== '') {
                    hasErrors = true;
               } else {
                    post[field.name] = field.value;
               }
               return field;
          });

          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
          }));
          if (!hasErrors && directSubmit === 0) {
               if (action === 'UPDATE') post['charge_id'] = id;
               subtmitData(post);
          }
     }

     const subtmitData = async (post) => {
          const res = await httpClient.post(SAVE_CHARGES, post);
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    Alert.success(result.message);
                    navigate('/main/setting/charges');
               } else {
                    Alert.failure(result.message);
               }
          }
     }

     function handleReset() {
          const updatedFormFields = formData.formFields.map((field) => ({
               ...field,
               value: '',
          }));
          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
               formErrors: {},
          }));
     }

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title={title} subTitle="Charges" subTitleLink="main/setting/charges" />
                    <div className='col-md-6 m-auto'>
                         <FormLayout
                              formTitle={formData.formTitle}
                              formFields={formData.formFields}
                              formErrors={formData.formErrors}
                              onFieldChange={handleFieldChange}
                              onFieldBlur={handleFieldBlur}
                              onSubmit={handleSubmit}
                              handleReset={handleReset}
                         />
                    </div>

               </div>
          </AdminLayout>
     )
}
