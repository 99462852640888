import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AdmissionList from './AdmissionList'
import NewAdmission from './NewAdmission'

export default function AdmissionRoute() {
     return (
          <Routes>
               <Route path="/" element={<AdmissionList />} />
               <Route path="/new" element={<NewAdmission />} />
               <Route path="/update/:id" element={<NewAdmission />} />
          </Routes>
     )
}
