import numberToWords from 'number-to-words';

const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const convertAmountToText = (amount) => {
  const convertToIndianRupees = (value) => {
    // Split the amount into integer and decimal parts
    const [integerPart, decimalPart] = value.toString().split('.');

    // Convert the integer part to words and capitalize first letter
    const integerInWords = numberToWords.toWords(integerPart)
      .split(' ')
      .map(capitalizeFirstLetter)
      .join(' ');

    // Convert the decimal part to words and capitalize first letter
    const decimalInWords = decimalPart
      ? ` and ${numberToWords.toWords(decimalPart)
          .split(' ')
          .map(capitalizeFirstLetter)
          .join(' ')} paise`
      : '';

    // Format the result
    const formattedResult = `${integerInWords}${decimalInWords}`;

    return formattedResult+' Only';
  };

  return convertToIndianRupees(amount);
};

export default convertAmountToText;
