import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import { useParams } from 'react-router-dom';
import httpClient from '../../../http/HttpRequest';
import { DISCHARGE_SUMMARY } from '../../../data/url';
import Alert from '../../../feature/Alert';
import { useReactToPrint } from 'react-to-print';
import { convertTo12HourFormat } from '../../../data/constant';
import Helper from '../../../helper/Helper';
import InvoiceHeader from './InvoiceHeader';

export default function DischargeCard() {

     const [patient, setPatient] = useState({});
     const [admission, setAdmission] = useState({});
     const [hospital, setHospital] = useState({});

     const tdStyle = { wordWrap: " break-word", whiteSpace: "normal", overflow: "hidden", lineHeight: "22px" };

     const { id } = useParams();

     useEffect(() => {
          if (id) {
               dischargeDetails();
          }
     }, [id])

     const dischargeDetails = async () => {
          if (id) {
               const res = await httpClient.post(DISCHARGE_SUMMARY, { invoice_id: id });
               if (res.requestStatus === 'OK') {
                    const result = res.result;
                    if (result.status === 1) {
                         setPatient(result.data.patient);
                         setAdmission(result.data.admission);
                         setHospital(result.data.hospital);
                    } else {
                         Alert.error(result.message);
                    }
               } else {
                    Alert.error(res.message);
               }
          }
     };

     const componentRef = React.useRef();

     const handlePrint = useReactToPrint({
          content: () => componentRef.current
     });



     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Discharge Card" subTitle="Invoices" subTitleLink="main/invoice" />
                    <div className="col-lg-11 m-auto grid-margin stretch-card">
                         <div className="card">
                              <div className='card-body print_section' id="print_section" ref={componentRef}>
                                   <style>
                                        {`                    
                                        @media print {            
                                             td {                             
                                                  font-size:18px !important;                                                
                                                  padding:10px !important;
                                                  line-height:1.6;
                                             }
                                        }
                                   `}
                                   </style>
                                   <InvoiceHeader label="Discharge Summary"  />                                   
                                   <br />
                                   <div style={{ width: "90%" }} className='m-auto'>
                                        <table className='table table-bordered table-sm'>
                                             <tr>
                                                  <td style={{ width: "60%" }}><b>PATIENT NAME & ADDRESS</b></td>
                                                  <td style={{ width: "20%" }}><b>Age</b></td>
                                                  <td style={{ width: "20%" }}><b>Sex</b></td>
                                             </tr>
                                             <tr>
                                                  <td style={{ width: "60%" }}>{patient.patient_name} <br /><address style={{ marginTop: "10px", marginBottom: "0" }}>{patient.address}</address></td>
                                                  <td style={{ width: "20%" }}>{admission.age}</td>
                                                  <td style={{ width: "20%" }}>{patient.gender}</td>
                                             </tr>
                                        </table>
                                        <table className='table table-bordered table-sm'>
                                             <tr>
                                                  <td style={{ width: "25%" }}><b>Date of Admission</b></td>
                                                  <td style={{ width: "25%" }}><b>Date of Discharge</b></td>
                                                  <td style={{ width: "25%" }}><b>Weight on Admission</b></td>
                                                  <td style={{ width: "25%" }}><b>Weight on Discharge</b></td>
                                             </tr>
                                             <tr>
                                                  <td style={{ width: "25%" }}>{admission.admission_date != '' && admission.admission_date != null ? Helper.formatDate(admission.admission_date) : ''}</td>
                                                  <td style={{ width: "25%" }}>{admission.discharge_date != '' && admission.discharge_date != null ? Helper.formatDate(admission.discharge_date) : ''}</td>
                                                  <td style={{ width: "25%" }}>{admission.admission_weight} KG</td>
                                                  <td style={{ width: "25%" }}>{admission.discharge_weight} KG</td>
                                             </tr>
                                             <tr>
                                                  <td style={{ width: "25%" }}>TOA : {convertTo12HourFormat(admission.admission_time)}</td>
                                                  <td style={{ width: "25%" }}>TOD : {convertTo12HourFormat(admission.discharge_time)}</td>
                                                  <td style={{ width: "25%" }}>UHID : {admission.uhid}</td>
                                                  <td style={{ width: "25%" }}>IPD NO : {admission.ipd_no}</td>
                                             </tr>
                                        </table>
                                        <table className='table table-bordered table-sm'>
                                             <tr>
                                                  <td style={tdStyle}>
                                                       <b>Reason For Admission : </b> {admission.admission_reason}
                                                  </td>
                                             </tr>
                                             <tr>
                                                  <td style={tdStyle}>
                                                       <b>Significant Findings : </b> {admission.significant_findings}
                                                  </td>
                                             </tr>
                                             <tr>
                                                  <td style={tdStyle}>
                                                       <b>Diagnosis : {admission.diagnosis}</b>
                                                  </td>
                                             </tr>
                                             <tr>
                                                  <td style={tdStyle}>
                                                       <b>Medicines & Course in Hospital :</b> {admission.medicines}
                                                  </td>
                                             </tr>
                                             <tr>
                                                  <td style={tdStyle}>
                                                       <b>Investigations :</b>
                                                       <div dangerouslySetInnerHTML={{ __html: admission.investigation }} />
                                                  </td>
                                             </tr>
                                             <tr>
                                                  <td style={tdStyle}>
                                                       <b>Condition at Discharge :</b> {admission.conditions}
                                                  </td>
                                             </tr>
                                             <tr>
                                                  <td style={tdStyle}>
                                                       <b>Advice at Discharge :</b>
                                                       <div dangerouslySetInnerHTML={{ __html: admission.advice }} />
                                                  </td>
                                             </tr>
                                        </table>
                                        <div className='mt-3 text-right'>
                                             {hospital.doctor_name} <br />{hospital.post}
                                        </div>
                                        <hr />
                                   </div>
                              </div>
                              <div className='m-2 text-center'>
                                   <button className="btn btn-sm btn-info btn-icon-text m-1" onClick={handlePrint}>PRINT</button>
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout >
     )
}
