import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import { DELETE_PAYMENT, PAYMENT_LIST } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import { PAYMENT_MODE } from '../../../data/constant';
import TableLayout from '../layout/TableLayout';
import Alert from '../../../feature/Alert';
import FormLayout from '../layout/FormLayout';
import { Link } from 'react-router-dom';

export default function PaymentList() {

     const [paymentList, setPaymentList] = useState([]);

     const tableHeaders = ['#','Number','Name', 'Amount', 'Ref No', 'Date',  'Remark', 'View', 'Delete'];

     const keys = ["number","patient_name", "amount", "reference", "payment_date", "remark", "btn3","btnDelete"];
     useEffect(() => {
          getPaymentList();
     }, []);

     const getPaymentList = async (payload = {}) => {
          const res = await httpClient.post(PAYMENT_LIST, payload);
          if (res.requestStatus == "OK") {
               const result = res.result;
               if (result.status == 1) {
                    setPaymentList(result.data);
               }
          }
     }

     const deletePayment = async (id) => {
          const res = await httpClient.post(DELETE_PAYMENT, { id: id });
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    getPaymentList();
                    Alert.success(result.message);
               } else {
                    Alert.error(result.message);
               }
          }
     }
     const object = [
          { key: 'mode', value: PAYMENT_MODE }
     ];
     const btnData = {
          btnDelete: { name: "Delete", method: deletePayment },
          btn3: {  name: "View", link: "/main/payment/view"}
     }

     const [formData, setFormData] = useState({
          formTitle: 'Search Customer',
          formFields: [
               { label: 'From Date', id: 'from_date', type: 'date', name: 'from_date', value: '', className: "col-md-2" },
               { label: 'To Date', id: 'to_date', type: 'date', name: 'to_date', value: '', className: "col-md-2" },
               { label: 'Mobile', id: 'mobile', type: 'number', name: 'mobile', value: '', className: "col-md-3", placeholder: 'Enter mobile' },
               { label: 'Payment Number', id: 'payment_number', type: 'number', name: 'payment_number', value: '', className: "col-md-3", placeholder: 'Enter number' },
          ],
          formErrors: { from_date: '', to_date: '', mobile: '', payment_number :''},
     });

     const handleFieldChange = (fieldName, value) => {
          setFormData((prevFormData) => {
               const updatedFormFields = prevFormData.formFields.map((field) =>
                    field.name === fieldName ? { ...field, value: value } : field
               );
               return {
                    ...prevFormData,
                    formFields: updatedFormFields,
               };
          });
     }

     const handleFieldBlur = () => { }

     const handleSubmit = (e) => {
          e.preventDefault();
          let post = {};
          const updatedFormFields = formData.formFields.map((field) => {
               post[field.name] = field.value;
               return field;
          });
          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
          }));
          getPaymentList(post);
     }


     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Payment" newButton="New" newButtonLink="main/payment/new" />
                    <div className="col-lg-12 grid-margin stretch-card">
                         <div className="card">
                              <div className="card-body">
                                   <Link className="btn btn-sm btn-danger mb-2 float-right" to={"/main/payment/refund"}>
                                        Refund Payment
                                   </Link>
                                   <div className='mb-2'>
                                        <FormLayout
                                             formTitle={formData.formTitle}
                                             formFields={formData.formFields}
                                             formErrors={formData.formErrors}
                                             onFieldChange={handleFieldChange}
                                             onSubmit={handleSubmit}
                                             onFieldBlur={handleFieldBlur}
                                             search
                                             isModal
                                        />
                                   </div>
                                   <TableLayout
                                        headers={tableHeaders}
                                        keys={keys}
                                        tableData={paymentList}
                                        id="id"                                       
                                        btnData={btnData}
                                   />
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
