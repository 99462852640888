import React, { createContext, useState, useEffect, useContext } from 'react';
import { GET_USER } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import { useNavigate } from 'react-router-dom';

const UserContext = createContext();

export const useUserContext = () => {
     return useContext(UserContext);
};

export const UserProvider = ({ children }) => {
     const [userData, setUserData] = useState({});

     const navigate = useNavigate();

     useEffect(() => {
          getUser();
     }, [])

     const getUser = async (e) => {
          try {
               const res = await httpClient.post(GET_USER, {});
               if (res.requestStatus === 'OK' && res.result.status === 1) {
                    setUserData(res.result.data);
               }else{
                    navigate('/login');
               }
          } catch (error) {
               console.error('User error:', error);
          }
     }

     return (
          <UserContext.Provider value={{ userData }}>
               {children}
          </UserContext.Provider>
     );
};