import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Helper from '../../../helper/Helper';
import ExportToExcel from '../../../helper/ExportToExcel';

export default function TableLayout(props) {
    const { tableData, keys, btnData, id, statusKey, statusObject, numberKey, object, refreshData } = props;
    const style = {
        padding: "5px 5px"
    }
    const [itemsPerPage, setItemsPerPage] = useState(15);

    const [currentPage, setCurrentPage] = useState(1);

    const [searchQuery, setSearchQuery] = useState('');

    const perPage = [10, 15, 20, 30, 50];

    const filteredData = searchQuery
        ? tableData.filter((item) =>
            keys.some(key => {
                const propertyValue = item[key];
                return propertyValue && propertyValue.toString().toLowerCase().includes(searchQuery.toLowerCase());
            })
        )
        : tableData;

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    }

    const pageNumbers = [];
    const range = 3;

    for (let i = currentPage - range; i <= currentPage + range; i++) {
        if (i > 0 && i <= totalPages) {
            pageNumbers.push(i);
        }
    }

    const openModal = (modalName, u_id = null) => {

    };

    const numberPerPage = (event) => {
        const value = event.target.value;
        setItemsPerPage(value);
    }

    return (
        <div>
            <input
                type="text"
                className="form-control form-control-sm mb-2 float-right"
                placeholder="Search..."
                value={searchQuery}
                style={{ width: "200px", height: "2rem" }}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className='row'>
                <div className='col-md-1 '>
                    <ExportToExcel data={tableData} filename="hospital.xlsx" />
                </div>
                <div className='col-md-2'>
                    <select className='form-control form-control-sm' value={itemsPerPage} style={{ height: "2rem" }} onChange={numberPerPage}>
                        {perPage.map((value) => (
                            <option key={`perPage${value}`} value={value}>
                                {value} Item
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-striped table-hover table-bordered">
                    <thead className='bg-primary text-white'>
                        {props.headers && (
                            <tr className='text-center'>
                                {props.headers.map((header, index) => (
                                    <th className='font-weight-bold' key={`th-${index}`}>{header}</th>
                                ))}
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {currentItems.map((row, index) => (
                            <tr key={`tr-${index}`} className='text-center'>
                                <td key={`td${index}`}>{indexOfFirstItem + index + 1}</td>
                                {keys.map((k, i) => (
                                    <React.Fragment key={`react-fg-${i}`}>
                                        {k === 'btnEdit' && (
                                            <td key={`react-fg-td-btnEdit-${i}`} className='text-center' style={style}>
                                                {btnData[k].modal ? (
                                                    <button onClick={(e) => openModal(btnData[k].modalName, row[id])} title={btnData[k].name} className="btn btn-sm btn-outline-primary">
                                                        {btnData[k].name}
                                                    </button>
                                                ) : (
                                                    <Link to={`${btnData[k].link}/${row[id]}`}>
                                                        <button title={btnData[k].name} className="btn btn-sm btn-outline-primary">
                                                            {btnData[k].name}
                                                        </button>
                                                    </Link>
                                                )}
                                            </td>
                                        )}
                                        {k === 'btnDelete' && (
                                            <td key={`react-fg-td-btnDelete-${i}`} className='text-center' style={style}>
                                                <button title={btnData[k].name} className="btn  btn-sm btn-outline-danger" onClick={() => btnData[k].method(row[id])} >{btnData[k].name}</button>
                                            </td>
                                        )}
                                        {k === 'btn3' && (
                                            <td key={`react-fg-td-btn3-${i}`} className='text-center' style={style}>
                                                <Link to={`${btnData[k].link}/${row[id]}`}><button title={btnData[k].name} className="btn  btn-sm btn-outline-primary">{btnData[k].name}</button></Link>
                                            </td>
                                        )}
                                        {k === statusKey && props.statusObject && (
                                            <td key={`react-fg-td-statusKey-${i}`} className={`text-center ${props.statusObject[row[k]].colorClass}`} style={style}>
                                                {statusObject[row[k]].name}
                                            </td>
                                        )}
                                        {numberKey && numberKey.indexOf(k) !== -1 && (
                                            <td key={`react-fg-td-numberKey-${i}`} style={style} className='text-right font-weight-bold'>
                                                {Helper.formatNumber([row[k]])}
                                            </td>
                                        )}
                                        {object ? (
                                            object.find(obj => obj.key === k) ?
                                                <td key={`react-fg-td-object-${i}`}>{object.find(obj => obj.key === k).value[row[k]]}</td> :
                                                (numberKey ? numberKey.indexOf(k) === -1 : true) && (
                                                    <td style={style} key={i}>
                                                        {row[k]}
                                                    </td>
                                                )
                                        ) : (
                                            k !== 'btnEdit' &&
                                            k !== 'btnDelete' &&
                                            k !== 'btn3' &&
                                            k !== statusKey &&
                                            (numberKey ? numberKey.indexOf(k) === -1 : true) && (
                                                <td style={k !== 'patient_name' ? style : {...style,width:"200px"} } key={`react-fg-td-default-${i}`}>
                                                    {row[k]}
                                                </td>
                                            )
                                        )
                                        }
                                    </React.Fragment>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <nav className='mt-2 float-right'>
                    <ul className="pagination">
                        <li style={{ cursor: "pointer" }} className="page-item" onClick={() => handlePageChange(1)} disabled={currentPage === 1}><span className="page-link" >FIRST</span></li>
                        {pageNumbers.map((number) => (
                            <li style={{ cursor: "pointer" }} key={number} onClick={() => handlePageChange(number)} className={currentPage === number ? 'page-item active' : 'page-item'}><span className="page-link" >{number}</span></li>
                        ))}
                        <li style={{ cursor: "pointer" }} onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} className="page-item"><span className="page-link" >LAST</span></li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
