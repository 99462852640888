import React from 'react'
import { HOSPITAL } from '../../../data/constant';
import { useUserContext } from '../user/UserContext';

export default function InvoiceFooter(props) {
     const { hospital, title } = props;
     const { userData } = useUserContext();
     return (
          <div className='mt-5 row'>
               <div className='col-md-8'>
                    {title == 'bill_to' ? 'Bill generated by' : 'Received & Thanks'},<br />
                    {userData && <span>From : {userData.name}</span>},<br />
                    {hospital.address}<br />
                    {hospital.district} {hospital.state} {hospital.pincode}<br />
                    Phone : {hospital.mobile}, Email : {hospital.email}
               </div>
               {title == 'bill_to' && <div className='col-md-4 text-right pt-5'>
                    {hospital.doctor_name} <br />
                    {HOSPITAL}
               </div>
               }
          </div>
     )
}
