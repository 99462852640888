import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AdminLayout from '../AdminLayout';
import ContentHeader from '../layout/ContentHeader';
import { ACTIVE_ADMISSIONS, UPDATE_INVOICE, VIEW_INVOICE } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import SelectPatient from '../layout/SelectPatient';
import Alert from '../../../feature/Alert';
import InvoiceCal from '../layout/InvoiceCal';

export default function EditInvoice() {
     const { id } = useParams();
     const navigate = useNavigate();

     const [order, setOrder] = useState({});
     const [details, setDetails] = useState([]);
     const [patient, setPatient] = useState({});
     const [notes, setNotes] = useState({});
     const [orderDetails, setOrderDetails] = useState({ ipd_id: '', invoice_number: '', invoice_date: '' });
     const [items, setItems] = useState([]);
     const [newPatient, setNewPatient] = useState({});
     const [ipdData, setIPDData] = useState([]);

     useEffect(() => {
          getOrderDetails();
     }, []);

     useEffect(() => {
          setNotes({ terms_conditions: order.terms_conditions, private_notes: order.notes });
          setOrderDetails({ ipd_id: order.admission_id, invoice_number: order.invoice_number, invoice_date: order.invoice_date })
     }, [patient, order]);

     useEffect(() => {
          if (details.length > 0) {
               const data = [];
               details.map((row, index) => {
                    data.push(
                         {
                              item: { label: row.item_name, value: row.charge_id },
                              desc: row.desc,
                              quantity: row.quantity,
                              price: row.amount,
                              total: row.total
                         }
                    )
               })
               setItems(data);
          }

     }, [details]);

     const getOrderDetails = async (e) => {
          const res = await httpClient.post(VIEW_INVOICE, { invoice_id: id });
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    setOrder(result.data.order);
                    setDetails(result.data.details);
                    setPatient(result.data.patient);
                    getActiveAdmissions(result.data.order.patient_id);
               }
          }
     }

     const handleInputChange = (fieldName, event) => {
          const value = event.target.value;
          const order = { ...orderDetails };
          order[fieldName] = value;
          setOrderDetails(order);
     };

     const handelSubmit = (items, commanData, totals) => {
          if (Object.keys(patient).length !== 0) {
               if (items.length > 0) {
                    let p = orderDetails;
                    p['invoice_id'] = id;
                    p['patient_id'] = newPatient.patient_id;
                    p['items'] = items;
                    p['totals'] = totals;
                    p['commonData'] = commanData;
                    saveOrder(p);
               } else {
                    Alert.error('Items are empty');
               }
          } else {
               Alert.error('Select Patient');
          }
     }

     const saveOrder = async (post) => {          
          const res = await httpClient.post(UPDATE_INVOICE, post);
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    Alert.success(result.message);
                    navigate('/main/invoice');
               } else {
                    Alert.failure(result.message);
               }
          }
     }     

     const getPatient = (data) => {
          setNewPatient(data);
     }

     const getActiveAdmissions = async (patient_id) => {
          let post = { patient_id: patient_id };
          const res = await httpClient.post(ACTIVE_ADMISSIONS, post);
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    setIPDData(result.data);
               } else {
                    Alert.failure(result.message);
               }
          }
     }

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Update Invoice" subTitle="Invoices" subTitleLink="main/invoice" />
                    <div className="col-lg-12 grid-margin stretch-card">
                         <div className='card'>
                              <div className='card-body'>
                                   <div className='row'>
                                        <div className='col-md-4'>
                                             <SelectPatient remitPatient={getPatient} patientId={patient.patient_id}/>
                                        </div>
                                        <div className='col-md-3'>
                                             <label htmlFor='number'>Admission IPD No</label>
                                             <select className='form-control form-control-sm' value={orderDetails.ipd_id} name="ipd_id" onChange={(e) => handleInputChange('ipd_id', e)} >
                                                  <option value="">Select</option>
                                                  {ipdData.map((item) => (
                                                       <option key={item.id} value={item.id}>
                                                            {item.ipd_no}
                                                       </option>
                                                  ))}
                                             </select>
                                        </div>
                                        <div className='col-md-2'>
                                             <label htmlFor='number'>Invoice Number</label>
                                             <input id='number' value={orderDetails.invoice_number} type='text' name='invoice_number' className='form-control form-control-sm' onChange={(e) => handleInputChange('invoice_number', e)} />
                                        </div>
                                        <div className='col-md-3'>
                                             <label htmlFor='invoice_date'>Invoice Date</label>
                                             <input id='invoice_date' value={orderDetails.invoice_date} type='date' name='invoice_date' className='form-control form-control-sm' onChange={(e) => handleInputChange('invoice_date', e)} />
                                        </div>
                                   </div>
                                   <hr />
                                   <div>
                                        <InvoiceCal patient={patient} onSubmit={handelSubmit} editItems={items} notes={notes} order={order}/>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
