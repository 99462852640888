import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Charges from './Charges';
import Profile from './Profile';
import ChangePassword from './ChangePassword';
import EditCharges from './EditCharges';

export default function SettingRoute() {
     return (
          <Routes>
               <Route path="/charges" element={<Charges />} />
               <Route path="/profile" element={<Profile />} />
               <Route path="/changePassword" element={<ChangePassword />} />
               <Route path="/addCharges" element={<EditCharges />} />
               <Route path="/editCharges/:id" element={<EditCharges />} />
          </Routes>
     )
}
