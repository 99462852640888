const Helper = {
  formatNumber: (number) => {
    return Number(number).toFixed(2);
  },

  formatDate: (date) => {
    const formattedDate = new Date(date);
    const year = formattedDate.getFullYear();
    const month = String(formattedDate.getMonth() + 1).padStart(2, '0');
    const day = String(formattedDate.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  },

  convertToDecimal: (stringValue) => {
    const parsedValue = parseFloat(stringValue);
    if (!isNaN(parsedValue)) {
      return parsedValue;
    } else {
      return false;
    }
  },

  convertToInteger: (stringValue) => {
    const parsedValue = parseInt(stringValue);
    if (!isNaN(parsedValue)) {
      return parsedValue;
    } else {
      return false;
    }
  },

  getToday: () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  },

  getCurrentTime: () => {
    const now = new Date();
    let hours = now.getHours();
    const minutes = String(now.getMinutes()).padStart(2, '0');
    // const ampm = hours >= 12 ? 'PM' : 'AM';
    // hours = hours % 12;
    // hours = hours ? hours : 12;
    hours = String(hours).padStart(2, '0');
    return `${hours}:${minutes}`;
  }
};

export default Helper;
