import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export default function FormLayout(props) {
    const { formFields, formErrors, onSubmit, onFieldChange, onFieldBlur, handleReset, isModal } = props;

    const [filter, setFilter] = useState(true);

    useEffect(() => {
        if (props.search) {
            setFilter(false);
        }
    }, [props.search])

    const handleSubmit = (event) => {
        event.preventDefault();
        if (onSubmit) {
            onSubmit(event);
        }
    };

    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (name) => (selectedOption) => {
        setSelectedOption(selectedOption);
        onFieldChange(name, selectedOption)
    };

    const onBlur = (name) => {
        if (selectedOption != null) {
            onFieldBlur(name, selectedOption.value)
        }
    }

    const openModal = (modalName) => {
        
    };

    const closeModal = (modalName, data = {}) => {        
    };

    const applyFilter = () => {
        setFilter(!filter);
    } 

    const handleEditorChange = (name) => (event, editor) => {
        const data = editor.getData();
        onFieldChange(name, data)
    };
    
    return (
        <>
            {props.search &&
                <div className='row'>
                    <div className='col-md-2 mb-2'>
                        <button className={`btn btn-sm ${!filter ? 'btn-primary' : 'btn-danger'}`} onClick={applyFilter}>{!filter ? 'Apply Filter' : 'Close Filter'}</button>
                    </div>
                </div>
            }
            {filter &&
                <div className={isModal ? "" : "card slideIn"}>
                    <div className={isModal ? "" : "card-body"}>
                        <form method='post' className="forms-sample" onSubmit={(e) => handleSubmit(e)}>
                            <div className='row'>
                                {formFields.map((field, index) => (
                                    <div key={`main-div-${index}`} className={field.className ? field.className : 'col-12'}>
                                        {(field.type === 'text' || field.type === 'number' || field.type === 'email' || field.type === 'date' || field.type === 'time' || field.type === 'password') &&
                                            <div className='form-group'>
                                                <label htmlFor={field.id}>{field.label}</label>
                                                {field.required && <span className='text-danger'> * </span>}
                                                <input
                                                    type={field.type}
                                                    id={field.id}
                                                    name={field.name}
                                                    placeholder={field.placeholder}
                                                    value={field.value}
                                                    onChange={(e) => onFieldChange(field.name, e.target.value)}
                                                    onBlur={(e) => onFieldBlur(field.name, e.target.value)}
                                                    className={`form-control form-control-sm ${formErrors[field.name] != '' ? 'border border-danger' : ''}`}
                                                />
                                                {formErrors[field.name] && (
                                                    <p style={{ color: "red" }}>{formErrors[field.name]}</p>
                                                )}
                                            </div>
                                        }

                                        {field.type === 'textarea' &&
                                            <div className='form-group'>
                                                <label htmlFor={field.id}>{field.label}</label>
                                                {field.required && <span className='text-danger'> * </span>}
                                                <textarea
                                                    id={field.id}
                                                    name={field.name}
                                                    placeholder={field.placeholder}
                                                    value={field.value}
                                                    onChange={(e) => onFieldChange(field.name, e.target.value)}
                                                    onBlur={(e) => onFieldBlur(field.name, e.target.value)}
                                                    className={`form-control form-control-sm ${formErrors[field.name] ? 'border border-danger' : ''}`}
                                                />
                                                {formErrors[field.name] && (
                                                    <p style={{ color: "red" }}>{formErrors[field.name]}</p>
                                                )}
                                            </div>
                                        }
                                        {field.type === 'htmltextarea' &&
                                            <div className='form-group'>
                                                <label htmlFor={field.id}>{field.label}</label>
                                                {field.required && <span className='text-danger'> * </span>}
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={field.value}
                                                    onChange={handleEditorChange(field.name)}
                                                    config={{
                                                        toolbar: [
                                                            'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'insertTable',
                                                            'tableColumn', 'tableRow', 'mergeTableCells', '|', 'undo', 'redo'
                                                        ],
                                                        table: {
                                                            contentToolbar: [
                                                                'tableColumn', 'tableRow', 'mergeTableCells'
                                                            ]
                                                        }
                                                    }}
                                                />                                             
                                                {formErrors[field.name] && (
                                                    <p style={{ color: "red" }}>{formErrors[field.name]}</p>
                                                )}
                                            </div>
                                        }
                                        {field.type === 'select' &&
                                            <div className='form-group'>
                                                <label>{field.label}
                                                    {field.required && <span className='text-danger'> * </span>}
                                                    {field.btn &&
                                                        (field.btn.link ?
                                                            <Link className='text-primary' to={field.btn.link}> {field.btn.label}</Link> : <span style={{ cursor: "pointer" }} className='text-primary' onClick={(e) => openModal(field.btn.method)}>{field.btn.label}</span>
                                                        )
                                                    }
                                                </label>
                                                {field.select ? (
                                                    <Select
                                                        value={field.value}
                                                        onChange={handleChange(field.name)}
                                                        options={field.options}
                                                        isSearchable
                                                        onBlur={(e) => onBlur(field.name)}
                                                        placeholder="Select"
                                                    />
                                                ) : (
                                                    <select
                                                        id={field.id}
                                                        name={field.name}
                                                        value={field.value}
                                                        onChange={(e) => onFieldChange(field.name, e.target.value)}
                                                        onBlur={(e) => onFieldBlur(field.name, e.target.value)}
                                                        className={`form-control form-control-sm ${formErrors[field.name] ? 'border border-danger' : ''}`}
                                                    >
                                                        <option value="">Select</option>
                                                        {field.options.map((option, index) => (
                                                            <option key={`select-box-${index}`} value={option.value}>
                                                                {option.label}
                                                            </option>
                                                        ))}
                                                    </select>)
                                                }
                                                {formErrors[field.name] && (
                                                    <p style={{ color: "red" }}>{formErrors[field.name]}</p>
                                                )}
                                            </div>
                                        }
                                        {field.type === 'radio' && (
                                            <div className='form-group'>
                                                <label htmlFor={field.id}>{field.label}</label>
                                                {field.required && <span className='text-danger'> * </span>}
                                                {field.options.map((option, index) => (
                                                    <div key={`radio-box-${index}`} className={`form-check form-check-${option.class}`}>
                                                        <label htmlFor={`${field.name}-${index}`} className="form-check-label">
                                                            <input
                                                                type="radio"
                                                                id={`${field.name}-${index}`}
                                                                name={field.name}
                                                                value={option.value}
                                                                checked={field.value === option.value}
                                                                onChange={(e) => onFieldChange(field.name, e.target.value)}
                                                                onBlur={(e) => onFieldBlur(field.name, e.target.value)}
                                                                className="form-check-input"
                                                            />
                                                            <i className="input-helper"></i>
                                                            {option.label}
                                                        </label>
                                                    </div>
                                                ))}
                                                {formErrors[field.name] && (
                                                    <p style={{ color: "red" }}>{formErrors[field.name]}</p>
                                                )}
                                            </div>
                                        )}
                                        {field.type === 'checkbox' && (
                                            <div className='form-group'>
                                                <label>{field.label}</label>
                                                {field.required && <span className='text-danger'> * </span>}
                                                {field.options.map((option, index) => (
                                                    <div key={`check-box-${index}`} className="form-check form-check-primary">
                                                        <label htmlFor={`${field.name}-${index}`} className="form-check-label">
                                                            <input
                                                                type="checkbox"
                                                                id={`${field.name}-${index}`}
                                                                name={field.name}
                                                                value={option.value}
                                                                checked={field.value.includes(option.value)}
                                                                onChange={(e) => {
                                                                    let checkedValues = '';
                                                                    if (e.target.checked) {
                                                                        checkedValues = option.value;
                                                                    } else {
                                                                        checkedValues = '';
                                                                    }
                                                                    onFieldChange(field.name, checkedValues);
                                                                }}
                                                                onBlur={(e) => onFieldBlur(field.name, field.value)}
                                                                className="form-check-input"
                                                            />
                                                            {option.label}<i className="input-helper"></i>
                                                        </label>
                                                    </div>
                                                ))}
                                                {formErrors[field.name] && (
                                                    <p style={{ color: "red" }}>{formErrors[field.name]}</p>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {
                                    props.search && <div className='col-md-2 mt-1'>
                                        <br />
                                        <button type="submit" className="btn btn-primary mr-2 auto_click">Search</button>
                                    </div>
                                }
                            </div>
                            {!props.search &&
                                <div className='text-center'>
                                    <button type="submit" className="btn btn-primary mr-2 auto_click">Submit</button>
                                    {isModal ? '' : <button type="reset" className="btn btn-secondary" onClick={handleReset}>
                                        Reset
                                    </button>
                                    }
                                </div>
                            }
                        </form>
                    </div>
                </div>
            }

        </>
    )
}
