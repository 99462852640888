export const USER_LOGIN = 'user/login';
export const GET_USER = 'user/userDetails';
export const SAVE_PATIENT = 'patient/savePatient';
export const PATIENT_LIST = 'patient/patientList';
export const GET_PATIENT = 'patient/getPatientById';
export const CHANGE_PASS = 'user/changePassword';
export const GET_CHARGES = 'charges/getChargeById';
export const SAVE_CHARGES = 'charges/saveCharges';
export const CHARGES_LIST = 'charges/chargeList';
export const UPDATE_PROFILE = 'user/updateProfile';
export const GET_PROFILE = 'user/profileDetails';
export const SELECT_PATIENT_LIST = 'patient/getPatient';
export const GET_PAYMENT_NUMBER = 'payment/getNumber';
export const SAVE_PAYMENT = 'payment/save';
export const REFUND_PAYMENT = 'payment/refund';
export const PAYMENT_DETAILS = 'payment/paymentDetails';
export const PAYMENT_LIST = 'payment/list';
export const DELETE_PAYMENT = 'payment/delete';
export const SAVE_INVOICE = 'invoice/saveInvoice';
export const UPDATE_INVOICE = 'invoice/updateInvoice';
export const INVOICE_LIST = 'invoice/list';
export const GET_INVOICE_NUMBER = 'invoice/getInvoiceNumber';
export const VIEW_INVOICE = 'invoice/viewInvoice';
export const DISCHARGE_SUMMARY = 'invoice/discharge';
export const DASHBOARD = 'user/dashboard';
export const GET_CHART_DATA = 'user/chart';
export const ADMISSION_LIST = 'admission/list';
export const GET_ADMISSION_DETAILS = 'admission/getDetails';
export const SAVE_ADMISSION = 'admission/new';
export const ACTIVE_ADMISSIONS = 'admission/activeList';
