import React, { useState } from 'react'
import logo from '../../../logo.png';
import favicon from '../../../favicon.png';
import { Link, useNavigate } from 'react-router-dom';
import Alert from '../../../feature/Alert';
import { useUserContext } from '../user/UserContext';

export default function Header() {

     const [rigthSidebar, setRightSidebar] = useState(false);

     const { userData } = useUserContext();

     const navigate = useNavigate();

     const toggleSidebar = (status) => {
          setRightSidebar(status);
     }

     const handelSidebar = () => {
          let sideBarStatus = localStorage.getItem("sideBarStatus");
          if (sideBarStatus === null || sideBarStatus === 'open') {
               localStorage.setItem('sideBarStatus', 'close');
               document.body.classList.add('sidebar-icon-only');
          } else {
               localStorage.setItem('sideBarStatus', 'open');
               document.body.classList.remove('sidebar-icon-only');
          }
     }

     const handelLogout = () => {
          localStorage.removeItem('token');
          localStorage.removeItem('user_id');
          Alert.success("Logout Success!");
          navigate('/login');
     }

     return (
          <>
               <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                    <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                         <Link className="navbar-brand brand-logo mr-5" to={"/main"}><img src={logo} className="mr-2" alt="logo" /></Link>
                         <Link className="navbar-brand brand-logo-mini" to={"/main"}><img src={favicon} alt="logo" /></Link>
                    </div>
                    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                         <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={handelSidebar}>
                              <span className="icon-menu"></span>
                         </button>
                         <ul className="navbar-nav mr-lg-2">
                              <li className="nav-item d-none d-lg-block">
                                   <a className="btn btn-sm btn-primary btn-rounded " onClick={(e) => setRightSidebar(true)}>
                                        <i className="icon-plus"></i>
                                   </a>
                              </li>
                              <li className="nav-item d-none d-lg-block">
                                   {userData && <span>Welcome : {userData.name}</span>}
                              </li>
                         </ul>
                         <ul className="navbar-nav navbar-nav-right">
                              <li className="nav-item nav-profile dropdown">
                                   <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
                                        <i className="icon-ellipsis"></i>
                                   </a>
                                   <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">                                      
                                        <a className="dropdown-item text-danger" onClick={handelLogout}>
                                             <i className="ti-power-off "></i>
                                             Logout
                                        </a>
                                   </div>
                              </li>
                         </ul>
                         <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" onClick={handelSidebar}>
                              <span className="icon-menu"></span>
                         </button>
                    </div>
               </nav>
               <div id="right-sidebar" className={`settings-panel ${rigthSidebar ? 'open' : ''}`}>
                    <i className="settings-close ti-close btn btn-sm" onClick={(e) => setRightSidebar(false)}></i>
                    <div className='p-3 mt-4'>

                    </div>
               </div>
          </>
     )
}
