import React, { useState } from 'react'
import logo from '../../logo.png';
import { USER_LOGIN } from '../../data/url';
import httpClient from '../../http/HttpRequest';
import Alert from '../../feature/Alert';
import { Link, useNavigate } from 'react-router-dom';

export default function Login() {

     const [username, setUsername] = useState('');
     const [password, setPassword] = useState('');
     const [errors, setErrors] = useState({});

     const navigate = useNavigate();

     const handelSubmit = async (e) => {
          e.preventDefault();

          let formErrors = {};
          let valid = true;

          if (!username) {
               formErrors.username = 'Username required';
               valid = false;
          } else if (username.length !== 10) {
               formErrors.username = 'Username shoule be 10 digit';
               valid = false;
          } else if (isNaN(Number(username))) {
               formErrors.username = 'Username should be a number';
               valid = false;
          }
          if (!password) {
               formErrors.password = 'Password Required';
               valid = false;
          } else if (password.length < 6) {
               formErrors.password = 'Password minimum 6 digit long.';
               valid = false;
          }
          if (valid) {
               let payLoad = { mobile: username, password: password };
               const res = await httpClient.post(USER_LOGIN, payLoad);
               if (res.requestStatus === 'OK') {
                    const result = res.result;
                    if (result.status === 1) {
                         localStorage.setItem('token', result.data.token);
                         Alert.success(result.message);
                         navigate('/main');
                    } else {
                         Alert.error(result.message);
                    }
               }
          }
          setErrors(formErrors);
     }

     return (
          <div className="container-scroller">
               <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center auth px-0">
                         <div className="row w-100 mx-0">
                              <div className="col-lg-4 mx-auto  zoom-in">
                                   <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                        <div className="brand-logo text-center">
                                             <img src={logo} alt="logo" />
                                        </div>
                                        <h4>Hello! let's get started</h4>
                                        <h6 className="font-weight-light">Sign in to continue</h6>
                                        {errors.httpError && <p style={{ color: "red" }}>{errors.httpError}</p>}
                                        <form className="pt-3" onSubmit={(e) => handelSubmit(e)}>
                                             <div className="form-group">
                                                  <input
                                                       type="text"
                                                       className="form-control form-control-lg"
                                                       id="username"
                                                       name='username'
                                                       autoComplete="true"
                                                       onChange={(e) => setUsername(e.target.value)}
                                                       placeholder="Username" />
                                                  {errors.username && <p style={{ color: "red" }}>{errors.username}</p>}
                                             </div>
                                             <div className="form-group">
                                                  <input
                                                       autoComplete="true"
                                                       type="password"
                                                       className="form-control form-control-lg"
                                                       id="exampleInputPassword1"
                                                       name='password'
                                                       onChange={(e) => setPassword(e.target.value)}
                                                       placeholder="password" />
                                                  {errors.password && <p style={{ color: "red" }}>{errors.password}</p>}
                                             </div>
                                             <div className="mt-3">
                                                  <button className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" type='submit'>Login</button>
                                             </div>
                                        </form>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}
