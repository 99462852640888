import React from 'react'
import { Route, Routes } from 'react-router-dom';
import PatientList from './PatientList';
import NewPatient from './NewPatient';

export default function PatientRoutes() {
     return (
          <Routes>
               <Route path="/" element={<PatientList />} />
               <Route path="/new" element={<NewPatient />} />
               <Route path="/view/:id" element={<NewPatient />} />
               <Route path="/edit/:id" element={<NewPatient />} />
          </Routes>
     )
}
