import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import { GET_PROFILE, UPDATE_PROFILE } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import Alert from '../../../feature/Alert';
import FormLayout from '../layout/FormLayout';

export default function Profile() {

     const [directSubmit, setDirectSubmit] = useState(1);
     const [formData, setFormData] = useState({
          formTitle: 'Profile',
          formFields: [
               { label: 'Name', id: 'name', type: 'text', name: 'name', value: '', required: true, className: "col-12" },
               { label: 'email', id: 'email_id', type: 'email', name: 'email', value: '', required: true, className: "col-12" },
               { label: 'mobile', id: 'mobile', type: 'text', name: 'mobile', value: '', required: true, className: "col-12" },
          ],
          formErrors: { name: '', email: '', mobile: '' },
     });

     useEffect(() => {
          getProfile();
     }, []);

     function handleFieldChange(fieldName, value) {
          setDirectSubmit(0);
          setFormData((prevFormData) => {
               const updatedFormFields = prevFormData.formFields.map((field) =>
                    field.name === fieldName ? { ...field, value: value } : field
               );
               return {
                    ...prevFormData,
                    formFields: updatedFormFields,
               };
          });
     }

     function handleFieldBlur(fieldName, value) {
          setDirectSubmit(0);
          validateField(fieldName, value);
     }

     function validateField(fieldName, value) {
          setFormData((prevFormData) => {
               const updatedErrors = { ...prevFormData.formErrors };

               if (fieldName === 'name') {
                    updatedErrors.name = value.trim() === '' ? 'Name is required!' : '';
               }  else if (fieldName === 'email') {
                    updatedErrors.email = value.trim() === '' ? 'Email is required!' : '';
               } else if (fieldName === 'mobile') {
                    updatedErrors.mobile = value.trim() === '' ? 'Mobile is required!' : '';
               }
               return {
                    ...prevFormData,
                    formErrors: updatedErrors,
               };
          });
          return formData.formErrors;

     }

     function handleSubmit(e) {
          e.preventDefault();
          let post = {};
          let hasErrors = false;
          const updatedFormFields = formData.formFields.map((field) => {
               const allError = validateField(field.name, field.value);
               const error = allError[field.name];
               if (error !== '') {
                    hasErrors = true;
               } else {
                    post[field.name] = field.value;
               }
               return field;
          });

          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
          }));
          if (!hasErrors && directSubmit === 0) {
               subtmitData(post);
          }
     }

     const subtmitData = async (post) => {
          const res = await httpClient.post(UPDATE_PROFILE, post);
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    Alert.success(result.message);
               } else {
                    Alert.failure(result.message);
               }
          }
     }

     function handleReset() {
          const updatedFormFields = formData.formFields.map((field) => ({
               ...field,
               value: '',
          }));

          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
               formErrors: {},
          }));
     }

     const getProfile = async () => {
          const res = await httpClient.post(GET_PROFILE, {});
          if (res.requestStatus === 'OK') {
               const result = res.result;
               if (result.status === 1) {
                    setFormData(prevFormData => {
                         const updatedFormFields = prevFormData.formFields.map(field => {
                              if (field.name in result.data) {
                                   field.value = result.data[field.name];
                              }
                              return field;
                         });
                         return {
                              ...prevFormData,
                              formFields: updatedFormFields,
                         };
                    });
               } else {
                    Alert.error(res.message);
               }
          }
     };

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Profile" />
                    <div className='col-md-8 m-auto'>
                         <FormLayout
                              formTitle={formData.formTitle}
                              formFields={formData.formFields}
                              formErrors={formData.formErrors}
                              onFieldChange={handleFieldChange}
                              onFieldBlur={handleFieldBlur}
                              onSubmit={handleSubmit}
                              handleReset={handleReset}
                         />
                    </div>
               </div>
          </AdminLayout>
     )
}
