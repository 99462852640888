import React from 'react'
import Helper from '../../../helper/Helper'
import convertAmountToText from '../../../data/AmountToWord';

export default function PaymentTable({ payment }) {
     const headers = ['Number', 'Date', 'Reference', 'Charges', 'Amount',];
     const thStyle = { padding: "13px", verticalAlign: "center", fontSize: "12px" };
     const footerStyle = { lineHeight: 1, verticalAlign: "center", fontSize: "14px", color: "#3a3030", fontWeight: "500" };

     return (
          <div className='mt-3'>
               <table className="table table-bordered table-sm">
                    <thead>
                         <tr>
                              {headers.map((header, index) => (
                                   <th style={thStyle} key={index}>{header}</th>
                              ))}
                         </tr>
                    </thead>
                    <tbody>
                         <tr>
                              <td>{payment.number}</td>
                              <td>{Helper.formatDate(payment.payment_date)}</td>
                              <td>{payment.reference}</td>
                              <td>{Helper.formatNumber(payment.charges)}</td>
                              <td>{Helper.formatNumber(payment.amount)} </td>
                         </tr>
                    </tbody>
               </table>
               <div className='row mt-3'>
                    <div className='col-6'>
                         Notes : {payment.remark}
                    </div>
                    <div className='col-6'>
                         <table className='table table-sm table-borderless'>
                              <tbody>
                                   <tr>
                                        <td className='text-right' style={footerStyle}>Net Amount : </td>
                                        <td style={footerStyle} className='text-right'>{payment.amount}</td>
                                   </tr>
                              </tbody>
                         </table>
                    </div>
               </div>
               <div className='mt-2 p-1 float-right bg-primary text-white'>
                    <span style={{ ...footerStyle, color: "white" }}>
                         Net Amount in words : {payment.amount !== undefined ? convertAmountToText(payment.amount) : ''}
                    </span>
               </div>  

          </div>

     )
}
