import React from 'react'
import { PAYMENT_STATUS } from '../../../data/constant';
import convertAmountToText from '../../../data/AmountToWord';

export default function InvoiceTable(props) {

     const { details,order,title } = props;

     const headers = ['#', 'Item', 'Amount', 'Quantity', 'Total'];

     const thStyle = { padding: "5px", lineHeight: 2, verticalAlign: "center", fontSize: "15px", fontWeight: "500" };
     const footerStyle = { lineHeight: 1, verticalAlign: "center", fontSize: "14px", color: "#3a3030", fontWeight: "500" };
     const fontSize = { fontSize: "14px", lineHeight: 2, color: "#3a3030"}

     return (
          <>
               <style>
                    {`                    
                    @media print {
                         .table{                        
                              color: black;
                         }                  
                         .td {                             
                              font-size:18px !important;
                         }
                    }
                `}
               </style>

               <div className='container-fluid'>
                    <div className='row'>
                         <table className="table table-sm table-bordered">
                              <thead>
                                   <tr className='text-center'>
                                        {headers.map((header, index) => (
                                             <th className='headers' style={thStyle} key={index}>{header}</th>
                                        ))}
                                   </tr>
                              </thead>
                              <tbody>
                              {
                                   details.map((item, index) => (
                                        <tr key={index}>
                                             <td className='td' style={fontSize}>{index + 1}</td>
                                             <td className='td' style={{ width: "460px", ...fontSize }}>{item.item_name} <br /><small>{item.desc}</small></td>
                                             <td className='text-right td' style={fontSize}>{item.amount}</td>
                                             <td className='text-right td' style={fontSize}>{item.quantity}</td>
                                             <td className='text-right td' style={fontSize}>{item.total}</td>
                                        </tr>
                                   ))
                              }
                              </tbody>
                              <tfoot>
                                   <tr>
                                        <td style={thStyle} colSpan="2">Total</td>                                   
                                        <td colSpan="2"></td>                                
                                        <td style={fontSize} className="text-right">{order.total_amount}</td>
                                   </tr>
                              </tfoot>
                         </table>
                    </div>
                    <div className='row mt-2'>
                         <div className='col-4' style={{ paddingLeft: '0px' }}>     
                              {title === 'bill_to' && PAYMENT_STATUS[order.payment_status] && (
                                   <p style={fontSize}>
                                        Payment Status : <span className={PAYMENT_STATUS[order.payment_status].colorClass}>{PAYMENT_STATUS[order.payment_status].name}</span>
                                   </p>
                              )}

                         </div>
                         <div className='col-8' style={{paddingRight:'0px'}}>
                              <table className='table table-sm table-borderless'>
                                   <tbody>
                                        <tr>
                                             <td className='text-right td' style={footerStyle}>Total Amount : </td>
                                             <td style={footerStyle} className='text-right td'>{order.total_amount}</td>
                                        </tr>
                                        <tr>
                                             <td className='text-right td' style={footerStyle}>Discount : </td>
                                             <td style={footerStyle} className='text-right td'>{order.discount_amount}</td>
                                        </tr>                                   
                                        <tr>
                                             <td className='text-right text-primary td' style={footerStyle} >Net Amount in Figure :</td>
                                             <td style={footerStyle} className='text-right text-primary td'>{order.net_amount}</td>
                                        </tr>
                                        {title === 'bill_to' && parseFloat(order.paid_amount) > 0 && (
                                             <tr>
                                                  <td className='text-right text-success td' style={footerStyle} >Paid Amount :</td>
                                                  <td style={footerStyle} className='text-right text-success td'>{order.paid_amount}</td>
                                             </tr>
                                        )}
                                        {title === 'bill_to' && parseFloat(order.pending_amount) > 0 && (
                                             <tr>
                                                  <td className='text-right text-danger td' style={footerStyle} >Pending Amount :</td>
                                                  <td style={footerStyle} className='text-right text-danger td'>{order.pending_amount}</td>
                                             </tr>
                                        )}
                                   </tbody>
                              </table>
                         </div>
                    </div>
                    <div className='mt-2 p-1 float-right'>
                         <span style={{ ...footerStyle}} className='td'>
                              Net Amount in words : {order.net_amount !== undefined ? convertAmountToText(order.net_amount) : ''}
                         </span>
                    </div>             
               </div>
          </>
     )
}
