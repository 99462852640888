import React from 'react'
import InvoiceList from './InvoiceList'
import NewInvoice from './NewInvoice'
import { Route, Routes } from 'react-router-dom'
import ViewInvoice from './ViewInvoice'
import EditInvoice from './EditInvoice'
import DischargeCard from './DischargeCard'

export default function InvoiceRoute() {
     return (
          <Routes>
               <Route path="/" element={<InvoiceList />} />
               <Route path="/new" element={<NewInvoice />} />
               <Route path="/view/:id" element={<ViewInvoice />} />
               <Route path="/edit/:id" element={<EditInvoice />} />
               <Route path="/card/:id" element={<DischargeCard />} />               
          </Routes>
     )
}
