import React from 'react';
import { PAYMENT_MODE } from '../../../data/constant';
import Helper from '../../../helper/Helper';

export default function InvoiceAddress(props) {
     const { patient, hospital, admission, order, title } = props;

     return (
          <>
               <style>
                    {`
                    .print-container .print-col {
                        font-weight: 600;   
                        color: black;                    
                    }
                    @media print {
                         .print-container {                        
                              color: black;
                         }                  
                         .print-container .print-col {                         
                              font-size:18px !important;
                         }
                    }
                `}
               </style>
               <div className="row mb-3 print-container">
                    <div className='col-2 print-col'>
                         Patient Name:
                    </div>
                    <div className='col-4 print-col'>
                         {patient.patient_name}
                    </div>
                    <div className='col-2 print-col'>
                         Address:
                    </div>
                    <div className='col-4 print-col'>
                         {patient.address}
                    </div>
                    {title === 'bill_to' && <>
                         <div className='col-2 mt-2 print-col'>
                              Age:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {admission.age}
                         </div>
                         <div className='col-2 mt-2 print-col'>
                              Gender:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {patient.gender}
                         </div>
                         <div className='col-2 mt-2 print-col'>
                              Bill No:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {order.invoice_number}
                         </div>
                         <div className='col-2 mt-2 print-col'>
                              Dr. In-Charge:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {hospital.doctor_name}
                         </div>
                         <div className='col-2 mt-2 print-col'>
                              Bill Date:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {order.invoice_date != '' && order.invoice_date != null ? Helper.formatDate(order.invoice_date) : ''}
                         </div>
                         <div className='col-2 mt-2 print-col'>
                              Admit Date:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {admission.admission_date != '' && admission.admission_date != null ? Helper.formatDate(admission.admission_date) : ''}
                         </div>
                         <div className='col-2 mt-2 print-col'>
                              Discharge Date:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {admission.discharge_date != '' && admission.discharge_date != null ? Helper.formatDate(admission.discharge_date) : ''}
                         </div>
                    </>}
                    {title === 'payment_receipt' && <>
                         <div className='col-2 mt-2 print-col'>
                              Payment Number:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {order.number}
                         </div>
                         <div className='col-2 mt-2 print-col'>
                              Payment Date:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {Helper.formatDate(order.payment_date)}
                         </div>
                         <div className='col-2 mt-2 print-col'>
                              Payment Mode:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {PAYMENT_MODE[order.mode]}
                         </div>
                         <div className='col-2 mt-2 print-col'>
                              Reference:
                         </div>
                         <div className='col-4 mt-2 print-col'>
                              {order.reference}
                         </div>
                    </>}
               </div>
          </>
     );
}
