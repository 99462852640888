
import React, { useState, useEffect } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import SelectPatient from '../layout/SelectPatient'
import Helper from '../../../helper/Helper';
import { ACTIVE_ADMISSIONS, GET_INVOICE_NUMBER, SAVE_INVOICE } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import InvoiceCal from '../layout/InvoiceCal';
import Alert from '../../../feature/Alert';
import { useNavigate } from 'react-router-dom';

export default function NewInvoice() {

     const [orderDetails, setOrderDetails] = useState({ ipd_id : '',invoice_number: '', invoice_date: Helper.getToday() });
     const [patient, setPatient] = useState({});
     const [ipdData, setIPDData] = useState([]);

     const getPatient = (data) => {
          setPatient(data);
          getActiveAdmissions(data.patient_id);
     }

     const navigate = useNavigate();

     const handleInputChange = (fieldName, event) => {
          const value = event.target.value;
          const order = { ...orderDetails };
          order[fieldName] = value;
          setOrderDetails(order);
     };

     useEffect(() => {
          getInvoiceNumber();
     }, [])

     const getInvoiceNumber = async (e) => {
          const res = await httpClient.post(GET_INVOICE_NUMBER, {});
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    setOrderDetails(prevOrderDetails => ({
                         ...prevOrderDetails,                       
                         invoice_number: result.invoice_number || ''
                    }));
               }
          }
     }

     const handelSubmit = (items, commanData, totals) => {
          if (Object.keys(patient).length !== 0) {
               if (items.length > 0) {
                    let p = orderDetails;
                    p['patient_id'] = patient.patient_id;
                    p['items'] = items;
                    p['totals'] = totals;
                    p['commonData'] = commanData;
                    saveOrder(p);
               } else {
                    Alert.error('Items are empty');
               }
          }else{
               Alert.error('Select Patient'); 
          }
     }

     const saveOrder = async (post) => {
          const res = await httpClient.post(SAVE_INVOICE, post);
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    Alert.success(result.message);
                    navigate('/main/invoice');
               } else {
                    Alert.failure(result.message);
               }
          }
     }

     const getActiveAdmissions = async(patient_id) => {
          let post = {patient_id : patient_id};
          const res = await httpClient.post(ACTIVE_ADMISSIONS, post);
          if (res.requestStatus === "OK") {
               const result = res.result;
               if (result.status === 1) {
                    setIPDData(result.data);
               } else {
                    Alert.failure(result.message);
               }
          }
     }

     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="New Invoice" subTitle="Invoices" subTitleLink="main/invoice" />
                    <div className="col-lg-12 grid-margin stretch-card">
                         <div className='card'>
                              <div className='card-body'>
                                   <div className='row'>
                                        <div className='col-md-4'>
                                             <SelectPatient remitPatient={getPatient} />
                                        </div>
                                        <div className='col-md-3'>
                                             <label htmlFor='number'>Admission IPD No</label>
                                             <select className='form-control form-control-sm' value={orderDetails.ipd_id} name="ipd_id" onChange={(e) => handleInputChange('ipd_id', e)} >
                                                  <option value="">Select</option>
                                                  {ipdData.map((item) => (
                                                       <option key={item.id} value={item.id}>
                                                            {item.ipd_no}
                                                       </option>
                                                  ))}
                                             </select>
                                        </div>
                                        <div className='col-md-2'>
                                             <label htmlFor='number'>Invoice Number</label>
                                             <input id='number' value={orderDetails.invoice_number} type='text' name='invoice_number' className='form-control form-control-sm' onChange={(e) => handleInputChange('invoice_number', e)} />
                                        </div>
                                        <div className='col-md-3'>
                                             <label htmlFor='invoice_date'>Invoice Date</label>
                                             <input id='invoice_date' value={orderDetails.invoice_date} type='date' name='invoice_date' className='form-control form-control-sm' onChange={(e) => handleInputChange('invoice_date', e)} />
                                        </div>
                                   </div>
                                   <hr />
                                   <div>
                                        <InvoiceCal patient={patient} onSubmit={handelSubmit} />
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
