import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import FormLayout from '../layout/FormLayout';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_ADMISSION_DETAILS, SAVE_ADMISSION } from '../../../data/url';
import Alert from '../../../feature/Alert';
import Helper from '../../../helper/Helper';
import httpClient from '../../../http/HttpRequest';
import SelectPatient from '../layout/SelectPatient';

export default function NewAdmission() {
     const { id } = useParams();    
     const [patient_id, setPatientId] = useState('');     

     const action = typeof id === 'undefined' ? 'ADD' : 'UPDATE';

     let title = "NEW ADMISSION";

     if (action === 'UPDATE') {
          title = "UPDATE ADMISSION";
     }

     const navigate = useNavigate();

     const [directSubmit, setDirectSubmit] = useState(1);

     const [formData, setFormData] = useState({
          formTitle: 'Admission',
          formFields: [
               { label: 'IPD No', id: 'ipd_no', type: 'number', name: 'ipd_no', value: '', required: true, className: "col-md-4" },
               { label: 'UHID', id: 'uhid', type: 'number', name: 'uhid', value: '', required: true, className: "col-md-4" },
               { label: 'Admission Date', id: 'admission_date', type: 'date', name: 'admission_date', value: Helper.getToday(), required: true, className: "col-md-4" },
               { label: 'Admission Time', id: 'admission_time', type: 'time', name: 'admission_time', value: Helper.getCurrentTime(), required: true, className: "col-md-4", },
               { label: 'Admission Weight (KG)', id: 'admission_weight', type: 'number', name: 'admission_weight', value: '', required: true, className: "col-md-4", placeholder: "Enter weight" },
               {
                    label: 'Status', id: 'status', type: 'radio', name: 'status', value: '', className: "col-md-4", options: [
                         { label: 'ADMITTED', value: "1", class: "success" },
                         { label: 'DISCHARGE', value: "2", class: "danger" },
                    ]
               },
               { label: 'Reason for Admission', id: 'admission_reason', type: 'textarea', name: 'admission_reason', value: '', required: true, className: "col-md-6", placeholder: "Enter details" },
               { label: 'Significant Findings', id: 'significant_findings', type: 'textarea', name: 'significant_findings', value: '', className: "col-md-6", placeholder: "Enter details" },
               { label: 'Diagnosis', id: 'diagnosis', type: 'text', name: 'diagnosis', value: '', className: "col-md-6", placeholder: "Enter diagnosis details" },
               { label: 'Medicines & Courses', id: 'medicines', type: 'textarea', name: 'medicines', value: '', className: "col-md-6", placeholder: "Enter details" },
               { label: 'Investigations', id: 'investigation', type: 'htmltextarea', name: 'investigation', value: '', className: "col-md-12", placeholder: "Enter details" },
               { label: 'Discharge Date', id: 'discharge_date', type: 'date', name: 'discharge_date', value: '', className: "col-md-4" },
               { label: 'Discharge Time', id: 'discharge_time', type: 'time', name: 'discharge_time', value: '', className: "col-md-4", },
               { label: 'Discharge Weight (KG)', id: 'discharge_weight', type: 'number', name: 'discharge_weight', value: '', className: "col-md-4", placeholder: "Enter weight" },
               { label: 'Conditions at Discharge', id: 'conditions', type: 'textarea', name: 'conditions', value: '', className: "col-md-12", placeholder: "Enter details" },
               { label: 'Advice at Discharge', id: 'advice', type: 'htmltextarea', name: 'advice', value: '', className: "col-md-12", placeholder: "Enter details" },
          ],
          formErrors: { ipd_no: '', uhid: '', admission_date: '', admission_time: '', admission_weight: '', admission_reason: '', significant_findings: '', diagnosis: '', medicines: '', discharge_date: '', discharge_weight: '', discharge_time: '', conditions: '', advice: '', investigation :''},
     });

     useEffect(() => {
          if (id) {
               getPatient();
          }
     }, [id])
     

     const remitPatient = (data) => {        
          setPatientId(data.patient_id);
     }

     const getPatient = async () => {
          if (id) {
               const res = await httpClient.post(GET_ADMISSION_DETAILS, { id: id });
               if (res.requestStatus === 'OK') {
                    const result = res.result;
                    if (result.status === 1) {
                         setFormData(prevFormData => {
                              const updatedFormFields = prevFormData.formFields.map(field => {
                                   if (field.name in result.data) {
                                        field.value = result.data[field.name];
                                   }
                                   return field;
                              });
                              return {
                                   ...prevFormData,
                                   formFields: updatedFormFields,
                              };
                         });
                         setPatientId(result.data.patient_id);
                    } else {
                         Alert.error(res.message);
                    }
               }
          }
     };

     const handleFieldChange = (fieldName, value) => {        
          setDirectSubmit(0);
          setFormData((prevFormData) => {
               const updatedFormFields = prevFormData.formFields.map((field) =>
                    field.name === fieldName ? { ...field, value: value } : field
               );
               return {
                    ...prevFormData,
                    formFields: updatedFormFields,
               };
          });
     }

     const handleFieldBlur = (fieldName, value) => {
          setDirectSubmit(0);
          validateField(fieldName, value);
     }

     const validateField = (fieldName, value) => {
          setFormData((prevFormData) => {
               const updatedErrors = { ...prevFormData.formErrors };
               if (fieldName === 'ipd_no') {
                    updatedErrors.ipd_no = value.trim() === '' ? 'IPID is required' : '';
               } else if (fieldName === 'uhid') {
                    updatedErrors.uhid = value.trim() === '' ? 'UHID is required' : '';
               } else if (fieldName === 'admission_date') {
                    updatedErrors.admission_date = value.trim() === '' ? 'Admission Date required' : '';
               }
               else if (fieldName === 'admission_weight') {
                    updatedErrors.admission_weight = value.trim() === '' ? 'Admission Weight required' : '';
               }
               else if (fieldName === 'admission_time') {
                    updatedErrors.admission_time = value.trim() === '' ? 'Admission Time required' : '';
               }
               else if (fieldName === 'status') {
                    updatedErrors.status = value.trim() === '' ? 'Status is required' : '';
               }
               return {
                    ...prevFormData,
                    formErrors: updatedErrors,
               };
          });
          return formData.formErrors;

     }

     const handleSubmit = (e) => {
          e.preventDefault();
          let post = {};
          let hasErrors = false;
          const updatedFormFields = formData.formFields.map((field) => {
               const allError = validateField(field.name, field.value);
               const error = allError[field.name];
               if (error !== '') {
                    hasErrors = true;
               } else {
                    post[field.name] = field.value;
               }
               return field;
          });

          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
          }));           
          if (!hasErrors) {
               if (action === 'UPDATE') post['id'] = id;
               subtmitData(post);
          }else{
               Alert.info('Kindly change details.');
          }
     }

     const subtmitData = async (post) => {           
          if (patient_id !== ''){
               post['patient_id'] = patient_id;              
               const res = await httpClient.post(SAVE_ADMISSION, post);
               if (res.requestStatus === "OK") {
                    const result = res.result;
                    if (result.status === 1) {
                         Alert.success(result.message);
                         navigate('/main/admission');
                    } else {
                         Alert.failure(result.message);
                    }
               }
          }else{
               Alert.error('Select Patient');
          }
     }

     const handleReset = () => {
          const updatedFormFields = formData.formFields.map((field) => ({
               ...field,
               value: '',
          }));
          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
               formErrors: {},
          }));
     }

    

     return (
          <AdminLayout>
               <ContentHeader title={title} subTitle="Admissions" subTitleLink="main/admission" />
               <div className=' col-lg-12 m-auto grid-margin '>
                    <div className='card'>
                         <div className='card-body'>
                              <div className='col-md-6'>
                                   <SelectPatient remitPatient={remitPatient} patientId={patient_id} />
                              </div>
                              <div className='col-md-12 mt-3'>
                                   <FormLayout
                                        formTitle={formData.formTitle}
                                        formFields={formData.formFields}
                                        formErrors={formData.formErrors}
                                        onFieldChange={handleFieldChange}
                                        onFieldBlur={handleFieldBlur}
                                        onSubmit={handleSubmit}
                                        handleReset={handleReset}
                                        isModal
                                   />                                  
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
