import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Alert = {
     success: (message) => {
          toast.success(message, {
               theme: "colored"
          });
     },
     failure: (message) => {
          toast.error(message, {
               theme: "colored"
          });
     },
     error: (message) => {
          toast.error(message, {
               theme: "colored"
          });
     },
     warning: (message) => {
          toast.warning(message, {
               theme: "colored"
          });
     },
     info: (message) => {
          toast.info(message, {
               theme: "colored"
          });
     },
};

export default Alert;
