import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import httpClient from '../../../http/HttpRequest';
import { DASHBOARD, GET_CHART_DATA } from '../../../data/url';
import Helper from '../../../helper/Helper'
import ContentHeader from '../layout/ContentHeader';
import ReceiptAnimation from '../../../lottie/ReceiptAnimation';
import LineChart from './LineChart';

export default function Dashboard() {

  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);

  const colors = ["card-dark-blue", "card-tale", "card-light-blue", "card-light-danger"];

  useEffect(() => {
    getData();
    getChartData();
  }, [])

  const getData = async (e) => {
    const res = await httpClient.post(DASHBOARD, {});
    if (res.requestStatus == "OK") {
      const result = res.result;
      if (result.status == 1) {
        setData(result.data);
      }
    }
  }

  const showData = () => {
    return Object.keys(data).map((key, index) => {
      const randomIndex = Math.floor(Math.random() * colors.length);
      return (
        <div className='col-md-3 mb-2 stretch-card transparent' key={index}>
          <div className={`card ${colors[randomIndex]}`}>
            <div className="card-body">
              <p className="mb-4">{key}</p>
              <p className="fs-30 mb-2">{Helper.formatNumber(data[key]['amount'])}</p>
              <p>Count : {data[key]['count']}</p>
            </div>
          </div>
        </div>
      );
    });
  }

  const getChartData = async () => {
    const res = await httpClient.post(GET_CHART_DATA, {});
    if (res.requestStatus == "OK") {
      const result = res.result;
      if (result.status == 1) {
        let d = {
          labels: result.months,
          invoice: result.amount,
          paid: result.paid_amount
        };
        setChartData(d);
      }
    }
  }

  return (
    <AdminLayout>
      <div className="content-wrapper">
        <ContentHeader title='Dashboard' />
        <div className="col-12 grid-margin stretch-card">
          <div className='card'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-12'>
                  <div className="col-md-12 grid-margin transparent">
                    <div className="row">
                      {showData()}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-5'>
                  <ReceiptAnimation />
                </div>
                <div className='col-md-7'>
                  <div style={{ width: '100%', margin: 'auto' }}>
                    <LineChart data={chartData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
