import React from 'react'
import Lottie from 'react-lottie';
import animationData from './Animation.json';

export default function ReceiptAnimation() {
     const defaultOptions = {
          loop: true,
          autoplay: true,
          animationData: animationData,
          speed: 0.2,
          rendererSettings: {
               preserveAspectRatio: 'xMidYMid slice'
          }
     };
     return (
          <div className='text-center'>
               <Lottie
                    options={defaultOptions}
                    height={300}
                    width={300}
               />
          </div>
     )
}
