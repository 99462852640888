import React, { useEffect, useState } from 'react'
import AdminLayout from '../AdminLayout'
import ContentHeader from '../layout/ContentHeader'
import { INVOICE_LIST } from '../../../data/url';
import httpClient from '../../../http/HttpRequest';
import { ORDER_STATUS } from '../../../data/constant';
import TableLayout from '../layout/TableLayout';
import FormLayout from '../layout/FormLayout';

export default function InvoiceList() {

     const [list, setOrderList] = useState([]);

     const tableHeaders = ['#', 'Patient', 'Invoice Date', 'Doc No', 'Status', 'Amount', 'Action'];

     const keys = ["name","invoice_date", "invoice_number", "status", "net_amount", "btnEdit"];

     const btnData = {
          btnEdit: {
               name: "View", link: "/main/invoice/view"
          }
     }

     useEffect(() => {
          getInvoices();
     }, []);

     const getInvoices = async (payload = {}) => {
          const res = await httpClient.post(INVOICE_LIST, payload);
          if (res.requestStatus == "OK") {
               const result = res.result;
               if (result.status == 1) {
                    setOrderList(result.data);
               }
          }
     }

     const [formData, setFormData] = useState({
          formTitle: 'Search Customer',
          formFields: [
               { label: 'From Date', id: 'from_date', type: 'date', name: 'from_date', value: '', className: "col-md-2" },
               { label: 'To Date', id: 'to_date', type: 'date', name: 'to_date', value: '', className: "col-md-2" },
               { label: 'Mobile', id: 'mobile', type: 'number', name: 'mobile', value: '', className: "col-md-3", placeholder: 'Enter mobile' },
               { label: 'Invoice Number', id: 'invoice_number', type: 'number', name: 'invoice_number', value: '', className: "col-md-3", placeholder: 'Enter number' },
          ],
          formErrors: { from_date: '', to_date: '', mobile: '', invoice_number:'' },
     });

     const handleFieldChange = (fieldName, value) => {
          setFormData((prevFormData) => {
               const updatedFormFields = prevFormData.formFields.map((field) =>
                    field.name === fieldName ? { ...field, value: value } : field
               );
               return {
                    ...prevFormData,
                    formFields: updatedFormFields,
               };
          });
     }

     const handleFieldBlur = () => { }

     const handleSubmit = (e) => {
          e.preventDefault();
          let post = {};
          const updatedFormFields = formData.formFields.map((field) => {
               post[field.name] = field.value;
               return field;
          });
          setFormData((prevFormData) => ({
               ...prevFormData,
               formFields: updatedFormFields,
          }));
          getInvoices(post);
     }


     return (
          <AdminLayout>
               <div className="content-wrapper">
                    <ContentHeader title="Invoices" newButton="New" newButtonLink="main/invoice/new" />
                    <div className="col-lg-12 grid-margin stretch-card">
                         <div className="card">
                              <div className="card-body">
                                   <div className='mb-2'>
                                        <FormLayout
                                             formTitle={formData.formTitle}
                                             formFields={formData.formFields}
                                             formErrors={formData.formErrors}
                                             onFieldChange={handleFieldChange}
                                             onSubmit={handleSubmit}
                                             onFieldBlur={handleFieldBlur}
                                             search
                                             isModal
                                        />
                                   </div>
                                   <TableLayout
                                        headers={tableHeaders}
                                        keys={keys}
                                        tableData={list}
                                        id="invoice_id"
                                        btnData={btnData}
                                        statusKey="status"
                                        statusObject={ORDER_STATUS}
                                        numberKey={["net_amount"]}
                                   />
                              </div>
                         </div>
                    </div>
               </div>
          </AdminLayout>
     )
}
